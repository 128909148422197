// Data sevice
import axios from "axios";
import authHeader from "./auth-header";

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class NotificationService {
  index() {
    return axios.get(VUE_APP_BASE_URL + "notification", {
      headers: authHeader(),
    });
  }
  latest() {
    return axios.get(VUE_APP_BASE_URL + "notification-latest", {
      headers: authHeader(),
    });
  }
  store(data) {
    return axios.post(VUE_APP_BASE_URL + "notification", data, {
      headers: authHeader(),
    });
  }
  update(data) {
    return axios.put(VUE_APP_BASE_URL + "notification/" + data.id, data, {
      headers: authHeader(),
    });
  }
  notification;
  delete(user) {
    return axios.delete(VUE_APP_BASE_URL + "notification/" + user.id, {
      headers: authHeader(),
    });
  }
}

export default new NotificationService();
