// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class RoomService {
    getAll() {
        return axios.get(VUE_APP_BASE_URL + 'room', { headers: authHeader() })
    }
    getReport() {
        return axios.get(VUE_APP_BASE_URL + 'reservation-report', { headers: authHeader() })
    }
    getCategories() {
        return axios.get(VUE_APP_BASE_URL + 'room-category', { headers: authHeader() })
    }
    store(resource) {
        return axios.post(VUE_APP_BASE_URL + 'room', resource, { headers: authHeader() })
    }
    update(resource) {
        return axios.put(VUE_APP_BASE_URL + 'room/' + resource.id, resource, { headers: authHeader() })
    }
    delete(resource) {
        return axios.delete(VUE_APP_BASE_URL + 'room/' + resource.id, { headers: authHeader() })
    }
    getOpeninLogs() {
        return axios.get(VUE_APP_BASE_URL + 'room-opening-logs', { headers: authHeader() })
    }
}

export default new RoomService()