<template>
    <div>
        <div class="card mb-3" id="ordersTable"
            data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'>
            <div class="card-header">
                <div class="row flex-between-center border-bottom pb-1">
                    <div class="col-4 col-sm-auto align-items-center pe-0">
                        <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                            <i class="fa fa-shopping-cart"></i>
                            Selling Points
                        </h5>
                        <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
                            Manage all selling points or order types
                        </p>
                    </div>
                    <div class="col-8 col-sm-auto ms-auto text-end ps-0">
                        <div id="orders-actions" class="d-flex">
                            <div class="search-box h1 me-1">
                                <input class="form-control search-input fuzzy-search" type="text" placeholder="Search..."
                                    aria-label="Search" v-model="searchValue" />
                                <span class="fas fa-search search-box-icon"></span>
                            </div>
                            <button @click="addNew" type="button" data-bs-toggle="modal" data-bs-target="#add-modal"
                                class="btn btn-falcon-primary rounded-circle btn-sm h2 ms-2">
                                <i class="fas fa-plus"></i>
                            </button>
                            <button @click="forceReload" class="btn btn-falcon-primary rounded-circle btn-sm h2 ms-2">
                                <i class="fa-solid fa-arrows-rotate"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body px-3 py-0">
                <!-- Menu items table  -->
                <EasyDataTable v-if="theme == 'dark'" id="tour-step-1" table-class-name="dark-table" alternating
                    buttons-pagination :headers="headers" :items="resources" :search-field="searchField"
                    :search-value="searchValue" :loading="loading">
                    <template #loading>
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </template>
                    <template #item-name="item">
                        <div class="my-2">
                            <strong>{{ item.name.toUpperCase() }}</strong>
                            <p class="mb-0 text-500 text-wrap">{{ item.email }}</p>
                        </div>
                    </template>
                    <template #item-created_at="item">
                        <div class="my-2">
                            {{ formatDateHuman(item.created_at) }}
                        </div>
                    </template>

                    <template #item-action="item">
                        <div class="dropdown font-sans-serif position-static">
                            <button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal" type="button"
                                id="order-dropdown-0" data-bs-toggle="dropdown" data-boundary="viewport"
                                aria-haspopup="true" aria-expanded="false">
                                <span class="fas fa-ellipsis-h fs--1"></span>
                            </button>
                            <div class="dropdown-menu dropdown-menu-end border py-0" aria-labelledby="order-dropdown-0">
                                <div class="py-2">
                                    <a @click="editItem(item)" class="dropdown-item" role="button" data-bs-toggle="modal"
                                        data-bs-target="#edit-modal">Edit</a>

                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item text-danger" role="button"
                                        @click="deleteRecource(item)">Delete</a>
                                </div>
                            </div>
                        </div>
                    </template>
                </EasyDataTable>
                <EasyDataTable v-else id="tour-step-1" table-class-name="customize-table" alternating buttons-pagination
                    :headers="headers" :items="resources" :search-field="searchField" :search-value="searchValue"
                    :loading="loading">
                    <template #loading>
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </template>
                    <template #item-name="item">
                        <div class="my-2">
                            <strong>{{ item.name.toUpperCase() }}</strong>
                            <p class="mb-0 text-500 text-wrap">{{ item.email }}</p>
                        </div>
                    </template>
                    <template #item-created_at="item">
                        <div class="my-2">
                            {{ formatDateHuman(item.created_at) }}
                        </div>
                    </template>

                    <template #item-action="item">
                        <div class="dropdown font-sans-serif position-static">
                            <button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal" type="button"
                                id="order-dropdown-0" data-bs-toggle="dropdown" data-boundary="viewport"
                                aria-haspopup="true" aria-expanded="false">
                                <span class="fas fa-ellipsis-h fs--1"></span>
                            </button>
                            <div class="dropdown-menu dropdown-menu-end border py-0" aria-labelledby="order-dropdown-0">
                                <div class="py-2">
                                    <a @click="editItem(item)" class="dropdown-item" role="button" data-bs-toggle="modal"
                                        data-bs-target="#edit-modal">Edit</a>

                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item text-danger" role="button"
                                        @click="deleteRecource(item)">Delete</a>
                                </div>
                            </div>
                        </div>
                    </template>
                </EasyDataTable>
                <!-- End Menu items table  -->
            </div>
            <div class="card-footer">
                <!-- footer content  -->
            </div>
        </div>

        <!-- Start edit modal  -->
        <div>
            <div class="modal fade" id="edit-modal" data-bs-keyboard="false" data-bs-backdrop="static" tabindex="-1"
                aria-labelledby="edit-modalLabel" aria-hidden="true">
                <div class="modal-dialog modal-md mt-6" role="document">
                    <div class="modal-content border-0">
                        <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
                            <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                                data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body p-0">
                            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                                <h4 class="mb-1" id="staticBackdropLabel">Edit Products</h4>
                            </div>
                            <div class="row my-3 mx-2">
                                <div class="col-12">
                                    <div class="d-flex">
                                        <div class="flex-1">
                                            <div class="row">
                                                <div class="mb-3 col-6">
                                                    <label class="col-form-label" for="recipient-name">Name</label>
                                                    <input class="form-control" id="recipient-name" type="text"
                                                        v-model="formData.name" :class="v$.formData.name.$dirty &&
                                                                v$.formData.name.$invalid
                                                                ? `is-invalid`
                                                                : v$.formData.name.$dirty
                                                                    ? `is-valid`
                                                                    : ``
                                                            " />
                                                    <div v-for="error of v$.formData.name.$errors" :key="error.$uid"
                                                        class="invalid-feedback" style="color: red">
                                                        This field is invalid
                                                    </div>
                                                </div>
                                                <div class="mb-3 col-6">
                                                    <label class="col-form-label" for="message-text">Icon</label>
                                                    <input class="form-control" id="recipient-name" type="text"
                                                        v-model="formData.icon" :class="v$.formData.icon.$dirty &&
                                                                v$.formData.icon.$invalid
                                                                ? `is-invalid`
                                                                : v$.formData.icon.$dirty
                                                                    ? `is-valid`
                                                                    : ``
                                                            " />
                                                    <div v-for="error of v$.formData.icon.$errors" :key="error.$uid"
                                                        class="invalid-feedback" style="color: red">
                                                        This field is invalid
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="flex-1">
                                            <div class="row">
                                                <label class="col-form-label" for="message-text">Description</label>
                                                <p class="text-word-break fs--1">
                                                    <textarea v-model="formData.properties" class="form-control"
                                                        id="exampleFormControlTextarea1" rows="3" :class="v$.formData.properties.$dirty &&
                                                                v$.formData.properties.$invalid
                                                                ? `is-invalid`
                                                                : v$.formData.properties.$dirty
                                                                    ? `is-valid`
                                                                    : ``
                                                            "></textarea>
                                                <div v-for="error of v$.formData.properties.$errors" :key="error.$uid"
                                                    class="invalid-feedback" style="color: red">
                                                    This field is invalid
                                                </div>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                                        <ul class="nav flex-lg-column fs--1">
                                            <li class="nav-item me-2 me-lg-0">
                                                <a @click="toggleActive" class="nav-link nav-link-card-details"
                                                    role="button"
                                                    :class="formData.show_in_login_screen ? `bg-success text-white` : ``">
                                                Show on POS Screen?
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block" type="button"
                                @click="updateResource(resource)">
                                <span class="fa fa-save me-2" data-fa-transform=""></span><span
                                    class="d-none d-sm-inline-block ms-1">Update</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End edit modal  -->
        <!-- Start add modal  -->
        <div class="modal fade" id="add-modal" data-bs-keyboard="false" data-bs-backdrop="static" tabindex="-1"
            aria-labelledby="add-modalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md mt-6" role="document">
                <div class="modal-content border-0">
                    <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
                        <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                            data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-0">
                        <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                            <h4 class="mb-1" id="staticBackdropLabel">Add Location</h4>
                        </div>
                        <div class="row my-3 mx-2">
                            <div class="col-12">
                                <div class="d-flex">
                                    <div class="flex-1">
                                        <div class="row">
                                            <div class="mb-3 col-6">
                                                <label class="col-form-label" for="recipient-name">Name</label>
                                                <input class="form-control" id="recipient-name" type="text"
                                                    v-model="formData.name" :class="v$.formData.name.$dirty &&
                                                            v$.formData.name.$invalid
                                                            ? `is-invalid`
                                                            : v$.formData.name.$dirty
                                                                ? `is-valid`
                                                                : ``
                                                        " />
                                                <div v-for="error of v$.formData.name.$errors" :key="error.$uid"
                                                    class="invalid-feedback" style="color: red">
                                                    This field is invalid
                                                </div>
                                            </div>
                                            <div class="mb-3 col-6">
                                                <label class="col-form-label" for="message-text">Icon</label>
                                                <input class="form-control" id="recipient-name" type="text"
                                                    v-model="formData.icon" :class="v$.formData.icon.$dirty &&
                                                            v$.formData.icon.$invalid
                                                            ? `is-invalid`
                                                            : v$.formData.icon.$dirty
                                                                ? `is-valid`
                                                                : ``
                                                        " />
                                                <div v-for="error of v$.formData.icon.$errors" :key="error.$uid"
                                                    class="invalid-feedback" style="color: red">
                                                    This field is invalid
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <div class="flex-1">
                                        <div class="row">
                                            <label class="col-form-label" for="message-text">Description</label>
                                            <p class="text-word-break fs--1">
                                                <textarea v-model="formData.properties" class="form-control"
                                                    id="exampleFormControlTextarea1" rows="3" :class="v$.formData.properties.$dirty &&
                                                            v$.formData.properties.$invalid
                                                            ? `is-invalid`
                                                            : v$.formData.properties.$dirty
                                                                ? `is-valid`
                                                                : ``
                                                        "></textarea>
                                            <div v-for="error of v$.formData.properties.$errors" :key="error.$uid"
                                                class="invalid-feedback" style="color: red">
                                                This field is invalid
                                            </div>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                                    <ul class="nav flex-lg-column fs--1">
                                        <li class="nav-item me-2 me-lg-0">
                                            <a @click="toggleActive" 
                                                class="nav-link nav-link-card-details"
                                                role="button"
                                                :class="formData.show_in_login_screen ? `bg-success text-white` : ``">
                                                Show on POS Screen?
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block" type="button"
                            @click="saveResource()">
                            <span class="fa fa-save me-2" data-fa-transform=""></span><span
                                class="d-none d-sm-inline-block ms-1">Save</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- End add modal  -->
    </div>
</template>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "flatpickr/dist/flatpickr.css";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";
import EasyDataTable from "vue3-easy-data-table";
import CartService from "../services/cart.service";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import { required, email } from "@vuelidate/validators";
import ProductService from "../services/product.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import MenuGroupService from "../services/menu_group.service";
import MenuItemSizeService from "../services/menu_item_size.service";
import OrderTypeService from "../services/order_types.service";

const now = new Date();

export default {
    mixins: [mixins],
    name: "CustomersView",
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            formData: {
                name: { required },
                icon: { required },
                properties: { required },
            },
        };
    },
    components: {
        NavDrawer,
        AppBar,
        flatPickr,
        FooterComp,
        LoaderOverlay,
        EasyDataTable,
    },
    data() {
        return {
            order_types: [],
            selected_category: "",
            menu_item_sizes: [],
            productCategories: [],
            groups: [],
            searchValue: "",
            table_keys: 1,
            formData: {
                group_id: "",
                active: 0,
                visible: 1,
                unit_name: "",
            },
            resources: [],
            orders_table_key: 0,
            searchField: ["id", "name", "price"],
            headers: [
                { text: "#", value: "id", sortable: true, widh: 10 },
                { text: "Name", value: "name", sortable: true },
                { text: "Details", value: "properties", sortable: true },
                { text: "Icon", value: "icon", sortable: true },
                { text: "Action", value: "action" },
            ],
        };
    },
    async created() {
        // this.forceReload();
        this.getData();
    },
    watch: {},
    computed: {
        loading() {
            return this.$store.getters.getLoading;
        },
        theme() {
            return localStorage.getItem("theme");
        },
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem("user"));
    },
    methods: {

        toggleActive() {
            this.formData.show_in_login_screen = !this.formData.show_in_login_screen;
        },
        addNew() {
            this.formData = {}
        },
        editItem(item) {
            console.log(item)
            this.formData = item
        },
        async selectOrderType(e) {
            this.$store.commit("SET_LOADING", true);
            await this.getItemsByOrder(e.target.value);
            this.$store.commit("SET_LOADING", false);
        },
        categorySelected(e) {
            console.log(e);
            this.getItemsByOrder(e.target.value);
        },
        sortItems(list) {
            return list.sort((a, b) => (a.name > b.name ? 1 : -1));
        },
        getMenuCategories() {
            this.$store.commit("SET_LOADING", true);
            return ProductService.getMenuCategories().then(
                (response) => {
                    if (response.data.status == "success") {
                        this.productCategories = response.data.data;

                        this.getItemsByOrder(response.data.data.at(0).id);
                    } else {
                        console.log(response.data.error);
                    }
                    this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                    console.log(error);
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                    this.$store.commit("SET_LOADING", false);
                }
            );
        },
        getItemsByOrder(item_id) {
            this.$store.commit("SET_LOADING", true);
            return CartService.byOrder(item_id).then(
                (response) => {
                    if (response.data.code == 200) {
                        this.resources = this.sortItems(response.data.data);
                        this.$store.commit("SET_LOADING", false);
                    } else {
                        console.log(response.data.error);
                    }
                },
                (error) => {
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                    this.$store.commit("SET_LOADING", false);
                }
            );
        },
        groupSelected(e) {
            this.formData.group_id = e.target.value;
        },
        getMenuItemSize() {
            return MenuItemSizeService.index().then(
                (response) => {
                    if (response.data.status == "success") {
                        this.menu_item_sizes = response.data.data;
                    } else {
                        console.log(response.data.error);
                    }
                },
                (error) => {
                    console.log(error);
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                }
            );
        },
        getGroups() {
            return MenuGroupService.index().then(
                (response) => {
                    if (response.data.status == "success") {
                        this.groups = response.data.data;
                    } else {
                        console.log(response.data.error);
                    }
                },
                (error) => {
                    console.log(error);
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                }
            );
        },
        toggleShowOnMain() {
            this.formData.show_on_main_page = !this.formData.show_on_main_page;
        },
        toggleRoot() {
            this.formData.is_root = !this.formData.is_root;
        },
        saveResource() {
            this.v$.formData.name.$touch();
            this.v$.formData.icon.$touch();
            this.v$.formData.properties.$touch();
            console.log(this.v$.formData);
            if (this.v$.formData.$invalid) {
                return;
            }
            this.$store.commit("SET_LOADING", true);
            OrderTypeService.store(this.formData).then(
                (response) => {
                    if (response.data.code == 200) {
                        toast.success(response.data.message, {
                            autoClose: 2000,
                        });
                        this.getData();
                    } else {
                        console.log(response.data.error);
                        toast.error(response.data.error, {
                            autoClose: 2000,
                        });
                    }
                    this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                    console.log(error);
                    toast.error(error);
                    this.$store.commit("SET_LOADING", false);
                }
            );
        },
        updateResource(resource) {
            this.v$.formData.name.$touch();
            this.v$.formData.icon.$touch();
            this.v$.formData.properties.$touch();
            console.log(this.formData);
            if (this.v$.formData.$invalid) {
                return;
            }
            this.$store.commit("SET_LOADING", true);
            OrderTypeService.update(this.formData).then(
                (response) => {
                    console.log(response);
                    if (response.data.code == 200) {
                        toast.success(response.data.message, {
                            autoClose: 2000,
                        });
                        this.getData();
                        // Clear modals
                        $("#edit-modal")
                            .removeClass("fade")
                            .hide();
                        $(".modal-backdrop").remove();
                    } else {
                        this.resources = [];
                        console.log(response.data.error);
                        toast.error(response.data.error, {
                            autoClose: 2000,
                        });
                    }
                    this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                    console.log(error);
                    this.$store.commit("SET_LOADING", false);
                }
            );
        },
        deleteRecource(resource) {
            let sure = confirm(
                "Are you sure you want to delete? you cannot undo this action."
            );
            if (sure) {
                this.$store.commit("SET_LOADING", true);
                OrderTypeService.delete(resource).then(
                    (response) => {
                        console.log(response);
                        if (response.data.code == 200) {
                            toast.success(response.data.message, {
                                autoClose: 2000,
                            });
                            this.getData();
                            $("#edit-modal")
                                .removeClass("fade")
                                .hide();
                            $(".modal-backdrop").remove();
                        } else {
                            console.log(response.data.error);
                            toast.error(response.data.error, {
                                autoClose: 2000,
                            });
                        }
                        this.$store.commit("SET_LOADING", false);
                    },
                    (error) => {
                        console.log(error);
                        this.$store.commit("SET_LOADING", false);
                    }
                );
            }
        },
        getData() {
            this.$store.commit("SET_LOADING", true);
            return OrderTypeService.index().then(
                (response) => {
                    if (response.data.code == 200) {
                        this.resources = response.data.data;
                    } else {
                        this.resources = [];
                        console.log(response.data.error);
                    }
                    this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                    console.log(error);
                    this.$store.commit("SET_LOADING", false);
                }
            );
        },
    },
};
</script>
