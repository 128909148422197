// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

class PrintService {
    printTest(url) {
        return axios.get(url + 'printTest.php', { headers: authHeader() })
    }
    printReceipt(url, resource) {
        return axios.post(url + 'printReceipt.php', resource, { headers: authHeader() })
    }
    printReceiptRoom(resource) {
        return axios.post('https://localhost/apps/bullapos/sdk/public/api/room-print-receipt', resource, { headers: authHeader() });
    }
}

export default new PrintService()