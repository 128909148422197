<template>
  <div>
    <!-- Start app bar  -->

    <nav
      class="navbar navbar-light navbar-glass navbar-top navbar-expand"
      style="display: none"
    >
      <button
        class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarVerticalCollapse"
        aria-controls="navbarVerticalCollapse"
        aria-expanded="false"
        aria-label="Toggle Navigation"
      >
        <span class="navbar-toggle-icon"><span class="toggle-line"></span></span>
      </button>

      <a class="navbar-brand me-1 me-sm-3" href="index.html">
        <div class="d-flex align-items-center">
          <img class="me-2" src="assets/img/logo.png" alt="" height="42" />
        </div>
      </a>

      <ul class="navbar-nav align-items-center d-none d-lg-block">
        <li class="nav-item">
          <!-- Start search -->
          <div class="search-box" data-list='{"valueNames":["title"]}'>
            <form
              class="position-relative"
              data-bs-toggle="search"
              data-bs-display="static"
            >
              <input
                class="form-control search-input fuzzy-search"
                type="search"
                placeholder="Search..."
                aria-label="Search"
              />

              <span class="fas fa-search search-box-icon"></span>
            </form>

            <div
              class="btn-close-falcon-container position-absolute end-0 top-50 translate-middle shadow-none"
              data-bs-dismiss="search"
            >
              <button
                class="btn btn-link btn-close-falcon p-0"
                aria-label="Close"
              ></button>
            </div>

            <div
              v-if="user"
              class="dropdown-menu border font-base start-0 mt-2 py-0 overflow-hidden w-100"
            >
              <div class="scrollbar list py-3" style="max-height: 24rem">
                <h6
                  class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2 border-bottom"
                >
                  Modules
                </h6>

                <a
                  v-for="item in user.modules"
                  :key="item.title"
                  class="dropdown-item fs--1 px-card py-1 hover-primary"
                  @click="goToLink(item.route)"
                  role="button"
                >
                  <div class="d-flex align-items-center">
                    <div class="fw-normal title">
                      <span class="fas fa-circle me-2 text-300 fs--2"></span
                      >{{ item.title }} | <br />

                      <template v-for="(subItem, i) in item.child" :key="i">
                        <a
                          v-if="user.permissions.includes('view-' + subItem.title)"
                          @click="goToLink(subItem.route)"
                          class=""
                          ><span class="fas fa-check me-2 text-300 fs--2"></span
                          >{{ subItem.title }}<br
                        /></a>
                      </template>
                    </div>
                  </div>
                </a>
                <hr class="text-200 dark__text-900" />
              </div>
              <div class="text-center mt-n3">
                <p class="fallback fw-bold fs-1 d-none">No Result Found.</p>
              </div>
            </div>
          </div>
          <!-- End search  -->
        </li>
      </ul>
      <ul class="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
        <li class="nav-item px-2">
          <div class="theme-control-toggle fa-icon-wait">
            <label
              class="mb-0 theme-control-toggle-label theme-control-toggle-light"
              for="themeControlToggle"
              data-bs-toggle="tooltip"
              @click="handleLogout"
              data-bs-placement="left"
              title="Logout"
            >
              <i class="fa-regular fa-right-from-bracket"></i
            ></label>
          </div>
        </li>

        <li class="nav-item d-none d-sm-block">
          <a
            class="nav-link notification-indicator notification-indicator-warning text-primary px-0 fa-icon-wait"
            role="button"
            @click="$router.push('/cart')"
            ><span
              class="fas fa-shopping-cart"
              data-fa-transform="shrink-7"
              style="font-size: 33px"
            >
            </span
            ><span class="notification-indicator-number">{{
              cartItems.pos.cart ? cartItems.pos.cart.length : 0
            }}</span></a
          >
        </li>

        <li class="nav-item dropdown">
          <a
            class="nav-link notification-indicator notification-indicator-warning text-primary px-0 fa-icon-wait"
            id="navbarDropdownNotification"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-hide-on-body-scroll="data-hide-on-body-scroll"
            ><span
              class="fas fa-bell"
              data-fa-transform="shrink-6"
              style="font-size: 33px"
            ></span>
            <span class="notification-indicator-number">{{
              notifications.length
            }}</span></a
          >
          <div
            class="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end dropdown-menu-card dropdown-menu-notification dropdown-caret-bg"
            aria-labelledby="navbarDropdownNotification"
          >
            <div class="card card-notification shadow-none">
              <div class="card-header">
                <div class="row justify-content-between align-items-center">
                  <div class="col-auto">
                    <h6 class="card-header-title mb-0">Notifications</h6>
                  </div>
                  <div role="button" class="col-auto ps-0 ps-sm-3">
                    <a class="card-link fw-normal" @click="markAsReadAll"
                      >Mark all as read</a
                    >
                  </div>
                </div>
              </div>
              <div class="scrollbar-overlay" style="max-height: 19rem">
                <div class="list-group list-group-flush fw-normal fs--1">
                  <div
                    v-for="(noti, i) in notifications"
                    :key="i"
                    class="list-group-item"
                  >
                    <a class="notification notification-flush notification-unread">
                      <div class="notification-body">
                        <h6 class="fw-bold">🔔 {{ noti.title }}</h6>
                        <p>
                          {{ noti.message }}
                        </p>
                        <span class="notification-time"
                          ><span class="me-2" role="img" aria-label="Emoji">🕐</span
                          >{{ formatDate(noti.created_at) }}</span
                        >
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div role="button" class="card-footer text-center border-top">
                <a class="card-link d-block" @click="$router.push('/notifications')"
                  >View all</a
                >
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item">
          <div class="theme-control-toggle fa-icon-wait px-2">
            <input
              class="form-check-input ms-0 theme-control-toggle-input"
              id="themeControlToggle"
              type="checkbox"
              data-theme-control="theme"
              value="dark"
            /><label
              class="mb-0 theme-control-toggle-label theme-control-toggle-light"
              for="themeControlToggle"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Switch to light theme"
              ><span class="fas fa-sun fs-0"></span></label
            ><label
              class="mb-0 theme-control-toggle-label theme-control-toggle-dark"
              for="themeControlToggle"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Switch to dark theme"
              ><span class="fas fa-moon fs-0"></span
            ></label>
          </div>
        </li>

        <!-- User Drop down  -->
        <li class="nav-item dropdown">
          <a
            class="nav-link pe-0 ps-2"
            id="navbarDropdownUser"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="avatar avatar-xl">
              <img class="rounded-circle" src="assets/img/team/avatar.png" alt="" />
            </div>
          </a>
          <div
            class="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end py-0"
            aria-labelledby="navbarDropdownUser"
          >
            <div v-if="user" class="bg-white dark__bg-1000 rounded-2 py-2">
              <a class="dropdown-item text-warning" href="https://bulla.co.ke"
                ><span class="me-1">😊</span
                ><span>{{ user.first_name }} - {{ user.last_name }}</span></a
              >
              <a class="dropdown-item">
                Role: <span class="text-primary"> {{ user.role_name }}</span>
              </a>
              <div class="dropdown-divider"></div>
              <!-- <a class="dropdown-item" @click="goToProfile"
              >Profile &amp; account</a
            >
            <div class="dropdown-divider"></div> -->
              <a
                v-if="user.permissions.includes('view-Configuration')"
                class="dropdown-item"
                @click="goToSettings"
                >Settings</a
              >
              <a class="dropdown-item" @click="handleLogout">Logout</a>
            </div>
          </div>
        </li>
        <!--End  User Drop down  -->
      </ul>
    </nav>
    <!-- Start menus  -->
    <!-- In vertical & combo  -->
    <nav
      class="navbar navbar-light navbar-glass navbar-top navbar-expand-lg"
      style="display: none"
      data-move-target="#navbarVerticalNav"
      data-navbar-top="combo"
    >
      <button
        class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarVerticalCollapse"
        aria-controls="navbarVerticalCollapse"
        aria-expanded="false"
        aria-label="Toggle Navigation"
      >
        <span class="navbar-toggle-icon"><span class="toggle-line"></span></span>
      </button>
      <a class="navbar-brand me-1 me-sm-3" href="bulla.co.ke">
        <div class="d-flex align-items-center">
          <img class="me-2" src="assets/img/logo.png" alt="" height="42" />
        </div>
      </a>
      <div v-if="user" class="collapse navbar-collapse scrollbar" id="navbarStandard">
        <!-- Combo view  -->
        <ul class="navbar-nav" data-top-nav-dropdowns="data-top-nav-dropdowns">
          <li v-for="item in user.modules" :key="item.title" class="nav-item dropdown">
            <!-- Without child  -->
            <a
              v-if="!item.child"
              class="nav-link"
              @click="goToLink(item.route)"
              role="button"
              :id="item.route"
              >{{ item.title }}
            </a>
            <!-- With child  -->
            <a
              v-if="item.child"
              class="nav-link dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              :id="item.title"
            >
              {{ item.title }}</a
            >
            <div
              class="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
              aria-labelledby="dashboards"
            >
              <div class="bg-white dark__bg-1000 rounded-3 py-2">
                <template v-for="(subItem, i) in item.child" :key="i">
                  <a
                    v-if="user.permissions.includes('view-' + subItem.title)"
                    @click="goToLink(subItem.route)"
                    class="dropdown-item link-600 fw-medium"
                    >{{ subItem.title }}</a
                  >
                </template>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <ul class="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
        <li class="nav-item dropdown">
          <a
            class="nav-link pe-0 ps-2"
            id="navbarDropdownUser"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="avatar avatar-xl">
              <img class="rounded-circle" src="assets/img/team/avatar.png" alt="" />
            </div>
          </a>
          <div
            class="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end py-0"
            aria-labelledby="navbarDropdownUser"
          >
            <div class="bg-white dark__bg-1000 rounded-2 py-2">
              <a class="dropdown-item fw-bold text-warning" href="widgets.html#!"
                ><span class="fas fa-crown me-1"></span><span>Go Pro</span></a
              >
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="pages/user/profile.html"
                >Profile &amp; account</a
              >
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="pages/user/settings.html">Settings</a>
              <a class="dropdown-item" @click="handleLogout">Logout</a>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import moment from "moment";
import AuthService from "../services/auth.service";
import NotificationService from "../services/notification.service";

export default {
  name: "AppBar",
  async beforeCreate() {
    let status = await AuthService.checkAuth();
    if (status == 401) {
      localStorage.removeItem("user");
      alert("Session expired");
      this.$router.push("/login");
    }
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth.user.is_admin;
    },
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
    cart() {
      return JSON.parse(localStorage.getItem("cart"));
    },
    cartItems() {
      return this.$store.state;
    },
    notifications() {
      return this.$store.getters.getNotifications;
    },
  },
  created() {
    this.getNotifications();
  },
  data() {
    return {
      notifications: [],
    };
  },
  mounted() {},
  methods: {
    formatDate(input) {
      return moment(input).format("DD-MM-YYYY h:mm:ss");
    },
    getNotifications() {
      return NotificationService.latest().then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_NOTIFICATIONS", response.data.data);
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    gotToPOS() {
      this.$router.push("/pos");
    },
    goToProfile() {
      this.$router.push("/profile");
    },
    goToSettings() {
      this.$router.push("/configuration");
    },
    handleLogout() {
      localStorage.clear();
      this.$router.push("/login");
    },
    goToLink(link_name) {
      console.log(link_name);
      this.$router.push(link_name);
    },
  },
};
</script>
