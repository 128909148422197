<template>
  <div>
      <div
        class="card mb-3"
        id="ordersTable"
        data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
      >
        <div class="card-header">
          <div class="row flex-between-center border-bottom pb-1">
            <div class="col-4 col-sm-auto align-items-center pe-0">
              <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                <i class="fa fa-shopping-cart text-primary"></i>
                Product groups
              </h5>
              <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
                Manage all available product groups
              </p>
            </div>
            <div class="col-8 col-sm-auto ms-auto text-end ps-0">
              <div class="d-none" id="orders-bulk-actions">
                <div class="d-flex">
                  <select
                    class="form-select form-select-sm"
                    aria-label="Bulk actions"
                  >
                    <option selected="">Bulk actions</option>
                    <option value="Refund">Refund</option>
                    <option value="Delete">Delete</option>
                    <option value="Archive">Archive</option></select
                  ><button
                    class="btn btn-falcon-default btn-sm ms-2"
                    type="button"
                  >
                    Apply
                  </button>
                </div>
              </div>
              <div id="orders-actions" class="d-flex">
                <div class="search-box h1">
                  <input
                    class="form-control search-input fuzzy-search"
                    type="text"
                    placeholder="Search..."
                    aria-label="Search"
                    v-model="searchValue"
                  />
                  <span class="fas fa-search search-box-icon"></span>
                </div>

                <button
                  class="btn btn-falcon-primary btn-sm ms-2 rounded-circle h2"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#add-modal"
                >
                  <span class="fas fa-plus" data-fa-transform=""></span
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body px-3 py-0">
          <!-- Menu items table  -->
          <EasyDataTable
            v-if="theme == 'dark'"
            id="tour-step-1"
            table-class-name="dark-table"
            alternating
            buttons-pagination
            :headers="headers"
            :items="resources"
            :search-field="searchField"
            :search-value="searchValue"
            :loading="loading"
          >
            <template #loading>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </template>
            <template #item-name="item">
              <div class="my-2">
                <strong>{{ item.name.toUpperCase() }}</strong>
                <p class="mb-0 text-500 text-wrap">{{ item.email }}</p>
              </div>
            </template>
            <template #item-created_at="item">
              <div class="my-2">
                {{ formatDateHuman(item.created_at) }}
              </div>
            </template>
            <template #item-menu_category="item">
              <span class="badge badge-soft-primary fs--1 m-1 p-1 d-flex">{{
                item.menu_category.name
              }}</span>
            </template>
            <template #item-action="item">
              <div class="dropdown font-sans-serif position-static">
                <button
                  class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                  type="button"
                  id="order-dropdown-0"
                  data-bs-toggle="dropdown"
                  data-boundary="viewport"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="fas fa-ellipsis-h fs--1"></span>
                </button>
                <div
                  class="dropdown-menu dropdown-menu-end border py-0"
                  aria-labelledby="order-dropdown-0"
                >
                  <div class="py-2">
                    <a
                      class="dropdown-item"
                      role="button"
                      @click="editItem(item)"
                      data-bs-toggle="modal"
                      data-bs-target="#edit-modal"
                      >Edit</a
                    >

                    <div class="dropdown-divider"></div>
                    <a
                      class="dropdown-item text-danger"
                      role="button"
                      @click="deleteRecource(item)"
                      >Delete</a
                    >
                  </div>
                </div>
              </div>
            </template>
          </EasyDataTable>
          <EasyDataTable
            v-else
            id="tour-step-1"
            table-class-name="customize-table"
            alternating
            buttons-pagination
            :headers="headers"
            :items="resources"
            :search-field="searchField"
            :search-value="searchValue"
            :loading="loading"
          >
            <template #loading>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </template>
            <template #item-name="item">
              <div class="my-2">
                <strong>{{ item.name.toUpperCase() }}</strong>
                <p class="mb-0 text-500 text-wrap">{{ item.email }}</p>
              </div>
            </template>
            <template #item-menu_category="item">
              <span v-if="item.menu_category" class="btn badge-soft-primary">{{
                item.menu_category.name
              }}</span>
            </template>
            <template #item-created_at="item">
              <div class="my-2">
                {{ formatDateHuman(item.created_at) }}
              </div>
            </template>

            <template #item-action="item">
              <div class="dropdown font-sans-serif position-static">
                <button
                  class="btn btn-primary dropdown-toggle btn-reveal"
                  type="button"
                  id="order-dropdown-0"
                  data-bs-toggle="dropdown"
                  data-boundary="viewport"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="fas fa-ellipsis-h fs--1"></span>
                </button>
                <div
                  class="dropdown-menu dropdown-menu-end border py-0"
                  aria-labelledby="order-dropdown-0"
                >
                  <div class="py-2">
                    <a
                      class="dropdown-item"
                      role="button"
                      @click="editItem(item)"
                      data-bs-toggle="modal"
                      data-bs-target="#edit-modal"
                      >Edit</a
                    >

                    <div class="dropdown-divider"></div>
                    <a
                      class="dropdown-item text-danger"
                      role="button"
                      @click="deleteRecource(item)"
                      >Delete</a
                    >
                  </div>
                </div>
              </div>
            </template>
          </EasyDataTable>
          <!-- End Menu items table  -->
        </div>
        <div class="card-footer">
          <!-- footer content  -->
        </div>
      </div>
    
      <!-- Start edit modal  -->
      <div v-for="(resource, i) in resources" :key="i">
        <div
          class="modal fade"
          id="edit-modal"
          data-bs-keyboard="false"
          data-bs-backdrop="static"
          tabindex="-1"
          aria-labelledby="edit-modalLabel'"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg mt-6" role="document">
            <div class="modal-content border-0">
              <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
                <button
                  class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body p-0">
                <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                  <h4 class="mb-1" id="staticBackdropLabel">Edit groups</h4>
                </div>
                <div class="row my-3 mx-2">
                  <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                    <div class="d-flex">
                      <span class="fa-stack ms-n1 me-3"
                        ><i
                          class="fa-inverse fa-stack-1x text-primary fas fa-tag"
                          data-fa-transform="shrink-2"
                        ></i
                      ></span>
                      <div class="flex-1">
                        <div class="row">
                          <div
                            class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                          >
                            <label class="col-form-label" for="recipient-name"
                              >Name</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              :placeholder="resource.name"
                              v-model="formData.name"
                              :class="
                                v$.formData.name.$dirty &&
                                v$.formData.name.$invalid
                                  ? `is-invalid`
                                  : v$.formData.name.$dirty
                                  ? `is-valid`
                                  : ``
                              "
                            />
                            <div
                              v-for="error of v$.formData.name.$errors"
                              :key="error.$uid"
                              class="invalid-feedback"
                              style="color: red"
                            >
                              This field is invalid
                            </div>
                          </div>
                          <div
                            class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                          >
                            <label class="col-form-label" for="message-text"
                              >Translated name</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              :placeholder="resource.translated_name"
                              v-model="formData.translated_name"
                              :class="
                                v$.formData.translated_name.$dirty &&
                                v$.formData.translated_name.$invalid
                                  ? `is-invalid`
                                  : v$.formData.translated_name.$dirty
                                  ? `is-valid`
                                  : ``
                              "
                            />
                            <div
                              v-for="error of v$.formData.translated_name
                                .$errors"
                              :key="error.$uid"
                              class="invalid-feedback"
                              style="color: red"
                            >
                              This field is invalid
                            </div>
                          </div>
                        </div>
                        <hr class="my-2" />
                      </div>
                    </div>
                    <div class="d-flex">
                      <span class="fa-stack ms-n1 me-3"
                        ><i
                          class="fa-inverse fa-stack-1x text-primary fas fa-align-left"
                          data-fa-transform="shrink-2"
                        ></i
                      ></span>
                      <div class="flex-1">
                        <div class="row">
                          <div class="mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <label class="col-form-label" for="message-text"
                              >Sort order</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="number"
                              :placeholder="resource.sort_order"
                              v-model="formData.sort_order"
                            />
                          </div>
                          <div class="mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <label class="col-form-label" for="message-text"
                              >Select category</label
                            >
                            <select
                              class="form-select"
                              id="group_id"
                              type="text"
                              v-model="formData.category_id"
                              @change="categorySelected"
                              :class="
                                v$.formData.category_id.$dirty &&
                                v$.formData.category_id.$invalid
                                  ? `is-invalid`
                                  : v$.formData.category_id.$dirty
                                  ? `is-valid`
                                  : ``
                              "
                            >
                              <option value="">Category...</option>
                              <option
                                v-for="(item, i) in menu_categories"
                                :key="i"
                                :value="item.id"
                              >
                                {{ item.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                    <ul class="nav flex-lg-column fs--1">
                      <li class="nav-item me-2 me-lg-0 my-2">
                        <a
                          @click="toggleActive"
                          class="nav-link nav-link-card-details"
                          role="button"
                          :class="resource.visible ? `bg-info text-white` : ``"
                          ><span class="fa fa-check me-2"></span>Active?
                        </a>
                      </li>
                      <br />
                      <hr />
                    </ul>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  class="btn btn-primary ms-2 rounded-pil h1 d-block"
                  type="button"
                  @click="updateResource"
                >
                  <i class="fa-solid fa-square-check"></i>
                  <span class="d-none d-sm-inline-block ms-1">Update</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End edit modal  -->

      <!-- Start add modal  -->
      <div
        class="modal fade"
        id="add-modal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg mt-6" role="document">
          <div class="modal-content border-0">
            <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
              <button
                class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-0">
              <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                <h4 class="mb-1" id="staticBackdropLabel">Add new group</h4>
              </div>
              <div class="p-4">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-lg-9 col-xl-9">
                    <div class="d-flex">
                      <span class="fa-stack ms-n1 me-3"
                        ><i
                          class="fa-inverse fa-stack-1x text-primary fas fa-tag"
                          data-fa-transform="shrink-2"
                        ></i
                      ></span>
                      <div class="flex-1">
                        <div class="row">
                          <div
                            class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                          >
                            <label class="col-form-label" for="recipient-name"
                              >Name</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              placeholder="e.g UGALI BEEF"
                              v-model="formData.name"
                              :class="
                                v$.formData.name.$dirty &&
                                v$.formData.name.$invalid
                                  ? `is-invalid`
                                  : v$.formData.name.$dirty
                                  ? `is-valid`
                                  : ``
                              "
                            />
                            <div
                              v-for="error of v$.formData.name.$errors"
                              :key="error.$uid"
                              class="invalid-feedback"
                              style="color: red"
                            >
                              This field is invalid
                            </div>
                          </div>
                          <div
                            class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                          >
                            <label class="col-form-label" for="message-text"
                              >Translated name</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              placeholder="Another name..."
                              v-model="formData.translated_name"
                              :class="
                                v$.formData.translated_name.$dirty &&
                                v$.formData.translated_name.$invalid
                                  ? `is-invalid`
                                  : v$.formData.translated_name.$dirty
                                  ? `is-valid`
                                  : ``
                              "
                            />
                            <div
                              v-for="error of v$.formData.translated_name
                                .$errors"
                              :key="error.$uid"
                              class="invalid-feedback"
                              style="color: red"
                            >
                              This field is invalid
                            </div>
                          </div>
                        </div>
                        <hr class="my-2" />
                      </div>
                    </div>
                    <div class="d-flex">
                      <span class="fa-stack ms-n1 me-3">
                        <i
                          class="fa-inverse fa-stack-1x text-primary fas fa-align-left"
                          data-fa-transform="shrink-2"
                        ></i
                      ></span>
                      <div class="flex-1">
                        <div class="row">
                          <div
                            class="mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                          >
                            <label class="col-form-label" for="message-text"
                              >Sort order</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="number"
                              placeholder="e.ge 123"
                              v-model="formData.sort_order"
                            />
                          </div>
                          <div class="mb-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <label class="col-form-label" for="message-text"
                              >Select category</label
                            >
                            <select
                              class="form-select"
                              id="group_id"
                              type="text"
                              v-model="formData.category_id"
                              @change="categorySelected"
                              :class="
                                v$.formData.category_id.$dirty &&
                                v$.formData.category_id.$invalid
                                  ? `is-invalid`
                                  : v$.formData.category_id.$dirty
                                  ? `is-valid`
                                  : ``
                              "
                            >
                              <option value="">Category...</option>
                              <option
                                v-for="(item, i) in menu_categories"
                                :key="i"
                                :value="item.id"
                              >
                                {{ item.name }}
                              </option>
                            </select>
                            <div
                              v-for="error of v$.formData.category_id.$errors"
                              :key="error.$uid"
                              class="invalid-feedback"
                              style="color: red"
                            >
                              This field is invalid
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                    <ul class="nav flex-lg-column fs--1">
                      <li class="nav-item me-2 me-lg-0 my-2">
                        <a
                          @click="toggleActive"
                          class="nav-link nav-link-card-details"
                          role="button"
                          :class="formData.visible ? `bg-info text-white` : ``"
                          ><span class="fa fa-check me-2"></span>Active?
                        </a>
                      </li>
                      <br />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-primary ms-2 rounded-pil h1 d-block"
                type="button"
                @click="saveResource"
              >
                <i class="fa-solid fa-square-check"></i>
                <span class="d-none d-sm-inline-block ms-1">Save</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- End add modal  -->
  </div>
</template>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "flatpickr/dist/flatpickr.css";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";
import EasyDataTable from "vue3-easy-data-table";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import { required, email } from "@vuelidate/validators";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import MenuGroupService from "../services/menu_group.service";
import MenuItemSizeService from "../services/menu_item_size.service";
import MenuCategoryService from "../services/menu_category.service";

const now = new Date();

export default {
  mixins: [mixins],
  name: "CustomersView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        name: { required },
        category_id: { required },
        translated_name: { required },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      groups: [],
      searchValue: "",
      table_keys: 1,
      formData: {
        group_id: "",
        active: 0,
        visible: 1,
        unit_name: "",
        category_id: ""
      },
      resources: [],
      orders_table_key: 0,
      menu_item_sizes: [],
      menu_categories: [],
      searchField: ["name", "mobile_no", "email"],
      headers: [
        { text: "#", value: "id", sortable: true, widh: 10 },
        { text: "Name", value: "name", sortable: true },
        { text: "Translated name", value: "translated_name", sortable: true },
        { text: "Sort order", value: "sort_order", sortable: true },
        { text: "Category", value: "menu_category", sortable: true },

        { text: "Action", value: "action" },
      ],
    };
  },
  created() {
    // this.forceReload();
    this.getData();
    this.getCategories();
    // this.getMenuItemSize();
  },
  watch: {
    resources(newVal, oldVal) {
      console.log("resource length", newVal.length);
      this.orders_table_key += 1;
    },
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    theme() {
      return localStorage.getItem("theme");
    },
  },
  methods: {
    groupSelected(e) {
      this.formData.group_id = e.target.value;
    },
    editItem(item) {
      this.formData = item;
    },
    getCategories() {
      this.$store.commit("SET_LOADING", true);
      return MenuCategoryService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.menu_categories = response.data.data;
          } else {
            this.menu_categories = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getMenuItemSize() {
      return MenuItemSizeService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.menu_item_sizes = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getGroups() {
      return MenuGroupService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    toggleShowOnMain() {
      this.formData.show_on_main_page = !this.formData.show_on_main_page;
    },
    toggleActive() {
      this.formData.active = !this.formData.active;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    saveResource() {
      this.v$.formData.$touch();
      console.log(this.v$.formData)
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      MenuGroupService.store(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          toast.error(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource() {
      this.$store.commit("SET_LOADING", true);
      MenuGroupService.update(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
          } else {
            this.resources = [];
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteRecource(resource) {
      let sure = confirm(
        "Are you sure you want to delete? you cannot undo this action."
      );
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        MenuGroupService.delete(resource).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.getData();
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    getData() {
      this.$store.commit("SET_LOADING", true);
      return MenuGroupService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
