import { createRouter, createWebHashHistory } from "vue-router";
import Login from "../views/LoginView.vue";
import SuppliersView from "../views/SuppliersView.vue";
import PosView from "../views/PosView.vue";
import CartView from "../views/CartView.vue";
import InvoicesView from "../views/InvoicesView.vue";
import RegisterView from "../views/RegisterView.vue";
import RequestDemoView from "../views/RequestDemoView.vue";
import InvoiceView from "../views/InvoiceView.vue";
import ReservedRoomsView from "../views/ReservedRoomsView.vue";
import BlankView from "../views/BlankView.vue";
import ReservationReportView from "../views/ReservationReportView.vue";
import UsersView from "../views/UsersView.vue";
import StoreView from "../views/StoreView.vue";
import QuickView from "../views/QuickView.vue";
import RoomsView from "../views/RoomsView.vue";
import RoomReservationsView from "../views/RoomReservationsView.vue";
import RolesView from "../views/RolesView.vue";
import PrinterView from "../views/PrinterView.vue";
import ModulesView from "../views/ModulesView.vue";
import MenuItemView from "../views/MenuItemView.vue";
import MenuGroupView from "../views/MenuGroupView.vue";
import TerminalsView from "../views/TerminalsView.vue";
import CustomersView from "../views/CustomersView.vue";
import DashboardView from "../views/DashboardView.vue";
import OpenOrdersView from "../views/OpenOrdersView.vue";
import SalesView from "../views/SalesView.vue";
import StockSheetView from "../views/StockSheetView.vue";
import OrderTypesView from "../views/OrderTypesView.vue";
import RoomsReportView from "../views/RoomsReportView.vue";
import SalesReportView from "../views/SalesReportView.vue";
import DailySalesReportView from "../views/DailySalesReportView.vue";
import DailyRestockReportView from "../views/DailyRestockReportView.vue";
import DailyStockTransferReportView from "../views/DailyStockTransferReportView.vue";
import DailyStockWasteReportView from "../views/DailyStockWasteReportView.vue";
import DailyStockAdjustmentReportView from "../views/DailyStockAdjustmentReportView.vue";

import LowInventoryReportView from "../views/LowInventoryReportView.vue";
import ProductivityReportView from "../views/ProductivityReportView.vue";
import MenuItemSizeView from "../views/MenuItemSizeView.vue";
import RightView from "../views/RightView.vue";
import WithdrawalsView from "../views/WithdrawalsView.vue";
import ExpensesView from "../views/ExpensesView.vue";
import PurchasesView from "../views/PurchasesView.vue";
import NewPurchasesView from "../views/NewPurchasesView.vue";
import MenuCategoryView from "../views/MenuCategoryView.vue";
import MenuEasyEditView from "../views/MenuEasyEditView.vue";
import CreditReportView from "../views/CreditReportView.vue";
import EshopView from "../views/EshopView.vue";
import ConfigurationView from "../views/ConfigurationView.vue";
import StatementView from "../views/StatementView.vue";
import PaymentView from "../views/PaymentView.vue";
import InventoryUnitView from "../views/InventoryUnitView.vue";
import ResetPasswordView from "../views/ResetPasswordView.vue";
import InventoryItemView from "../views/InventoryItemView.vue";
import NotificationsView from "../views/NotificationsView.vue";
import ForgotPasswordView from "../views/ForgotPasswordView.vue";
import InventoryGroupsView from "../views/InventoryGroupsView.vue";
import PaymentAnalysisView from "../views/PaymentAnalysisView.vue";
import MenuItemBarcodesView from "../views/MenuItemBarcodesView.vue";
import OpenTicketReportView from "../views/OpenTicketReportView.vue";
import StockSheetReportView from "../views/StockSheetReportView.vue";
import InventoryLocationView from "../views/InventoryLocationView.vue";
import InventoryWarehouseView from "../views/InventoryWarehouseView.vue";
import InventoryDashboardView from "../views/InventoryDashboardView.vue";
import SalesAnalysisReportView from "../views/SalesAnalysisReportView.vue";
import VATReportView from "../views/VATReportView.vue";
import ProfitAndLossView from "../views/ProfitAndLossView.vue";
import StocksDailyReportView from "../views/StocksDailyReportView.vue";
import ProfitAnalysisView from "../views/ProfitAnalysisView.vue";
import CustomPaymentReportView from "../views/CustomPaymentReportView.vue";
import TicketAnalysisReportView from "../views/TicketAnalysisReportView.vue";
import ProductCategoryReportView from "../views/ProductCategoryReportView.vue";
import SalesByLocationReportView from "../views/SalesByLocationReportView.vue";
import BestSellingReportView from "../views/BestSellingReportView.vue";
import ActivityLogView from "../views/ActivityLogView.vue";
import KitchenViewVue from "@/views/KitchenView.vue";

const routes = [
  {
    path: "/",
    name: "init",
    component: Login,
  },
  {
    path: "/configuration",
    name: "configuration",
    component: ConfigurationView,
    meta: { layout: "main" },
  },
  {
    path: "/eshop",
    name: "eshop",
    component: EshopView,
    meta: { layout: "main" },
  },
  {
    path: "/purchases",
    name: "purchases",
    component: PurchasesView,
    meta: { layout: "main" },
  },
  {
    path: "/new-purchases",
    name: "new-purchases",
    component: NewPurchasesView,
    meta: { layout: "main" },
  },
  {
    path: "/inventory-warehouse",
    name: "inventory-warehouse",
    component: InventoryWarehouseView,
    meta: { layout: "main" },
  },
  {
    path: "/pos-open-invoice",
    name: "invoices",
    component: InvoicesView,
    meta: { layout: "main" },
  },
  {
    path: "/invoice/:id?",
    name: "invoice",
    component: InvoiceView,
    meta: { layout: "main" },
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
  },
  {
    path: "/request-demo",
    name: "rerequest-demogister",
    component: RequestDemoView,
  },
  {
    path: "/barcode",
    name: "barcode",
    component: MenuItemBarcodesView,
    meta: { layout: "main" },
  },
  {
    path: "/stock-take",
    name: "stock-take",
    component: StockSheetView,
    meta: { layout: "main" },
  },
  {
    path: "/stock-sheet-report",
    name: "stock-sheet-report",
    component: StockSheetReportView,
    meta: { layout: "main" },
  },
  {
    path: "/stock-sheet/:id?",
    name: "stock-sheet",
    component: StockSheetReportView,
    meta: { layout: "main" },
  },
  {
    path: "/order-type",
    name: "order-type",
    component: OrderTypesView,
    meta: { layout: "main" },
  },
  {
    path: "/credit-note-report",
    name: "credit-note-report",
    component: CreditReportView,
    meta: { layout: "main" },
  },
  {
    path: "/inventory-unit",
    name: "inventory-unit",
    component: InventoryUnitView,
    meta: { layout: "main" },
  },
  {
    path: "/printers",
    name: "printers",
    component: PrinterView,
    meta: { layout: "main" },
  },
  {
    path: "/store",
    name: "store",
    component: StoreView,
    meta: { layout: "main" },
  },
  {
    path: "/pos",
    name: "pos",
    component: PosView,
    meta: { layout: "main" },
  },
  {
    path: "/quick-sale/:id?",
    name: "quick-sale",
    component: QuickView,
    meta: { layout: "main" },
  },
  {
    path: "/rooms",
    name: "Rooms",
    component: RoomsView,
    meta: { layout: "main" },
  },
  {
    path: "/suppliers",
    name: "suppliers",
    component: SuppliersView,
    meta: { layout: "main" },
  },
  {
    path: "/reservation",
    name: "reservation",
    component: RoomReservationsView,
    meta: { layout: "main" },
  },
  {
    path: "/inventory-items",
    name: "inventory-items",
    component: InventoryItemView,
    meta: { layout: "main" },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: NotificationsView,
    meta: { layout: "main" },
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    component: ResetPasswordView,
    meta: { layout: "main" },
  },

  {
    path: "/customers",
    name: "customers",
    component: CustomersView,
    meta: { layout: "main" },
  },
  {
    path: "/cart",
    name: "cart",
    component: CartView,
    meta: { layout: "main" },
  },

  {
    path: "/menu-category",
    name: "menu-category",
    component: MenuCategoryView,
    meta: { layout: "main" },
  },
  {
    path: "/menu-group",
    name: "menu-group",
    component: MenuGroupView,
    meta: { layout: "main" },
  },

  {
    path: "/menu-item-size",
    name: "menu-item-size",
    component: MenuItemSizeView,
    meta: { layout: "main" },
  },
  {
    path: "/right",
    name: "right",
    component: RightView,
    meta: { layout: "main" },
  },
  {
    path: "/withdrawals",
    name: "withdrawals",
    component: WithdrawalsView,
    meta: { layout: "main" },
  },

  {
    path: "/expenses",
    name: "expenses",
    component: ExpensesView,
    meta: { layout: "main" },
  },
  {
    path: "/inventory-location",
    name: "inventory-location",
    component: InventoryLocationView,
    meta: { layout: "main" },
  },
  {
    path: "/payment",
    name: "payment",
    component: PaymentView,
    meta: { layout: "main" },
  },
  {
    path: "/menu-item",
    name: "menu-item",
    component: MenuItemView,
    meta: { layout: "main" },
  },
  {
    path: "/invoice-report",
    name: "invoice-report",
    component: InvoicesView,
    meta: { layout: "main" },
  },
  {
    path: "/menu-easy-edit",
    name: "menu-easy-edit",
    component: MenuEasyEditView,
    meta: { layout: "main" },
  },

  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPasswordView,
  },
  {
    path: "/room-reservation-report",
    name: "room-reservation-report",
    component: RoomsReportView,
    meta: { layout: "main" },
  },
  {
    path: "/reservation",
    name: "reservation",
    component: ReservedRoomsView,
    meta: { layout: "main" },
  },
  {
    path: "/reservation-report",
    name: "reservation-report",
    component: ReservationReportView,
    meta: { layout: "main" },
  },

  {
    path: "/inventory",
    name: "inventory-dash",
    component: InventoryDashboardView,
    meta: { layout: "main" },
  },
  {
    path: "/inventory-group",
    name: "inventory-group",
    component: InventoryGroupsView,
    meta: { layout: "main" },
  },

  {
    path: "/home",
    name: "home",
    component: PosView,
    meta: { layout: "main" },
  },
  {
    path: "/terminals",
    name: "terminals",
    component: TerminalsView,
    meta: { layout: "main" },
  },

  {
    path: "/blank",
    name: "blank",
    component: BlankView,
    meta: { layout: "main" },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/modules",
    name: "modules",
    component: ModulesView,
    meta: { layout: "main" },
  },
  {
    path: "/users",
    name: "users",
    component: UsersView,
    meta: { layout: "main" },
  },
  {
    path: "/pos-open-order",
    name: "pos-open-order",
    component: OpenOrdersView,
    meta: { layout: "main" },
  },
  {
    path: "/sales",
    name: "sales",
    component: SalesView,
    meta: { layout: "main" },
  },

  {
    path: "/kitchen",
    name: "kitchen",
    component: KitchenViewVue,
    meta: { layout: "main" },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
    meta: { layout: "main" },
  },
  {
    path: "/roles",
    name: "role",
    component: RolesView,
    meta: { layout: "main" },
  },
  {
    path: "/sales-analysis-report",
    name: "sales-analysis-report",
    component: SalesAnalysisReportView,
    meta: { layout: "main" },
  },

  {
    path: "/sales-report",
    name: "sales-report",
    component: SalesReportView,
    meta: { layout: "main" },
  },
  {
    path: "/daily-sales-report",
    name: "daily-sales-report",
    component: DailySalesReportView,
    meta: { layout: "main" },
  },
  {
    path: "/daily-stock-transfers",
    name: "daily-stock-transfers",
    component: DailyStockTransferReportView,
    meta: { layout: "main" },
  },
  {
    path: "/daily-stock-waste",
    name: "daily-stock-waste",
    component: DailyStockWasteReportView,
    meta: { layout: "main" },
  },
  {
    path: "/daily-stock-adjustment",
    name: "daily-stock-adjustment",
    component: DailyStockAdjustmentReportView,
    meta: { layout: "main" },
  },

  {
    path: "/daily-stock-restocks",
    name: "daily-stock-restocks",
    component: DailyRestockReportView,
    meta: { layout: "main" },
  },

  {
    path: "/product-categories-report",
    name: "product-categories-report",
    component: ProductCategoryReportView,
    meta: { layout: "main" },
  },
  {
    path: "/best-selling",
    name: "best-selling",
    component: BestSellingReportView,
    meta: { layout: "main" },
  },
  {
    path: "/vat-report",
    name: "vat-report",
    component: VATReportView,
    meta: { layout: "main" },
  },
  {
    path: "/sales-by-location",
    name: "sales-by-location",
    component: SalesByLocationReportView,
    meta: { layout: "main" },
  },
  {
    path: "/sales-by-category",
    name: "sales-by-category",
    component: ProductCategoryReportView,
    meta: { layout: "main" },
  },
  {
    path: "/inventory-list",
    name: "inventory-list",
    component: ProductCategoryReportView,
    meta: { layout: "main" },
  },
  {
    path: "/sales-item-totals",
    name: "sales-item-totals",
    component: ProductCategoryReportView,
    meta: { layout: "main" },
  },
  {
    path: "/activity-logs",
    name: "activity-logs",
    component: ActivityLogView,
    meta: { layout: "main" },
  },
  {
    path: "/show-statement/:id?",
    component: StatementView,
    meta: { layout: "main" },
  },

  {
    path: "/low-inventory",
    name: "low-inventory",
    component: LowInventoryReportView,
    meta: { layout: "main" },
  },
  {
    path: "/user-productivity-report",
    name: "user-productivity-report",
    component: ProductivityReportView,
    meta: { layout: "main" },
  },
  {
    path: "/custom-payment",
    name: "custom-payment",
    component: CustomPaymentReportView,
    meta: { layout: "main" },
  },

  {
    path: "/open-ticket-summary",
    name: "open-ticket-summary",
    component: OpenTicketReportView,
    meta: { layout: "main" },
  },
  {
    path: "/payment-analysis-report",
    name: "payment_analysis_report",
    component: PaymentAnalysisView,
    meta: { layout: "main" },
  },
  {
    path: "/profit-analysis",
    name: "profit-analysis",
    component: ProfitAnalysisView,
    meta: { layout: "main" },
  },
  {
    path: "/profit-and-loss",
    name: "profit-and-loss",
    component: ProfitAndLossView,
    meta: { layout: "main" },
  },
  {
    path: "/stock-daily-report",
    name: "stock-daily-report",
    component: StocksDailyReportView,
    meta: { layout: "main" },
  },
  {
    path: "/ticket-analysis",
    name: "ticket-analysis",
    component: TicketAnalysisReportView,
    meta: { layout: "main" },
  },
];

const router = createRouter({
  // mode: "history",
  mode: "hash",
  linkActiveClass: "active",
  base: process.env.VUE_APP_ROOT_URL,
  history: createWebHashHistory(process.env.VUE_APP_ROOT_URL),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});

// control auth in all routes
router.beforeEach((to, from, next) => {
  const publicPages = [
    "/home",
    "/login",
    "/register",
    "/quick-sale",
    "/request-demo",
    "/forgot-password",
    "/register/enterprise",
    "/register/individual",
    "/register/starter",
    "/register/standard",
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("token");
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
