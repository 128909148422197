<template>
  <div>
    <div class="card mb-3" id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'>
      <div class="card-header">
        <div class="row flex-between-center border-bottom pb-1">
          <div class="col-4 col-sm-auto align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              <i class="fa fa-users text-warning"></i>
              All Suppliers
            </h5>
          </div>
          <div class="col-8 col-sm-auto ms-auto text-end ps-0">
            <div class="d-none" id="orders-bulk-actions">
              <div class="d-flex">
                <select class="form-select form-select-sm" aria-label="Bulk actions">
                  <option selected="">Bulk actions</option>
                  <option value="Refund">Refund</option>
                  <option value="Delete">Delete</option>
                  <option value="Archive">Archive</option>
                </select><button class="btn btn-falcon-default btn-sm ms-2" type="button">
                  Apply
                </button>
              </div>
            </div>
            <div id="orders-actions" class="d-flex">
              <button class="btn btn-falcon-warning btn-sm ms-2 my-auto" type="button" data-bs-toggle="modal"
                data-bs-target="#add-modal" @click="addNew">
                <span class="fas fa-plus-circle" data-fa-transform=""></span><span
                  class="d-none d-sm-inline-block ms-1">New</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body px-3 py-0">
        <div class="table-responsive scrollbar">
          <table class="table table-bordered table-striped fs--1 mb-0">
            <thead class="bg-200 text-900">
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Created At</th>
                <th>Status</th>
                <th>Statement</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in resources" :key="item.id">
                <td>{{ item.full_name }}</td>
                <td>{{ item.phone }}</td>
                <td>{{ item.email }}</td>
                <td>{{ formatDateHuman(item.created_at) }}</td>
                <td>
                  <button class="badge" :class="item.active == 1 ? 'btn-falcon-success' : 'btn-falcon-danger'">
                    {{ item.active == 1 ? "Active" : "Not Active" }}
                  </button>
                </td>
                <td>
                  <a class="text-primary" @click="$router.push('/show-statement-supplier/' + item.id)"
                    href="javascript:;">Statement</a>
                </td>
                <td>
                  <div class="dropdown font-sans-serif position-static">
                    <button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal" type="button"
                      id="order-dropdown-0" data-bs-toggle="dropdown" data-boundary="viewport" aria-haspopup="true"
                      aria-expanded="false">
                      <span class="fas fa-ellipsis-h fs--1"></span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end border py-0" aria-labelledby="order-dropdown-0">
                      <div class="py-2">
                        <a class="dropdown-item text-success" role="button"
                          @click="$router.push('/show-statement/' + item.id)">Statement</a>
                        <a class="dropdown-item" role="button" @click="editItem(item)" data-bs-toggle="modal"
                          data-bs-target="#edit-modal">Edit</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item text-danger" role="button" @click="deleteRecource(item)">Delete</a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <div class="d-flex justify-content-center mt-3">
            <pagination
              v-model="current_page"
              :records="records"
              :per-page="per_page"
              @paginate="myCallback"
            />
          </div> -->
      </div>
      <div class="card-footer">
        <!-- footer content  -->
      </div>
    </div>

    <!-- Start edit modal  -->
    <div class="modal fade" id="edit-modal" data-bs-keyboard="false" data-bs-backdrop="static" tabindex="-1"
      aria-labelledby="edit-modalLabel" aria-hidden="true">
      <div class="modal-dialog mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Edit Customer</h4>
            </div>
            <div class="row mx-2">
              <div class="col-12">
                <div class="row pt-0">
                  <div class="mb-2 col-8">
                    <label class="col-form-label" for="recipient-name">Full Name</label>
                    <input class="form-control" id="recipient-name" type="text" placeholder="e.g John Doe"
                      v-model="formData.full_name" :class="v$.formData.full_name.$dirty &&
                        v$.formData.full_name.$invalid
                        ? `is-invalid`
                        : v$.formData.full_name.$dirty
                          ? `is-valid`
                          : ``
                        " />
                    <div v-for="error of v$.formData.full_name.$errors" :key="error.$uid" class="invalid-feedback"
                      style="color: red">
                      This field is invalid
                    </div>
                  </div>
                  <div class="mb-2 col-4">
                    <div class="form-check form-switch mb-0 lh-1">
                      <input class="mx-2 form-check-input" type="checkbox" id="flexSwitchCheckPrimary"
                      :checked="formData.active" v-model="formData.active" />
                      <label class="form-check-label mb-0" for="flexSwitchCheckDefault">Active</label>
                    </div>
                  </div>
                  <div class="mb-2 col-6">
                    <label class="col-form-label" for="message-text">Email</label>
                    <input class="form-control" id="recipient-name" type="text" placeholder="e.g john@gmail.com"
                      v-model="formData.email" :class="v$.formData.email.$dirty && v$.formData.email.$invalid
                        ? `is-invalid`
                        : v$.formData.email.$dirty
                          ? `is-valid`
                          : ``
                        " />
                    <div v-for="error of v$.formData.email.$errors" :key="error.$uid" class="invalid-feedback"
                      style="color: red">
                      This field is invalid
                    </div>
                  </div>
                  <div class="mb-2 col-6">
                    <label class="col-form-label" for="message-text">Phone</label>
                    <input class="form-control" id="recipient-home" type="text" placeholder="e.g +25471234567"
                      v-model="formData.phone" :class="v$.formData.phone.$dirty && v$.formData.phone.$invalid
                        ? `is-invalid`
                        : v$.formData.phone.$dirty
                          ? `is-valid`
                          : ``
                        " />
                    <div v-for="error of v$.formData.phone.$errors" :key="error.$uid" class="invalid-feedback"
                      style="color: red">
                      This field is invalid
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <label class="col-form-label" for="message-text">Address</label>
                    <textarea v-model="formData.address" class="form-control" id="exampleFormControlTextarea1"
                      rows="3" :placeholder="formData.address"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer mt-2">
            <button class="btn me-auto border" data-bs-dismiss="modal" aria-label="Close">
              Close
            </button>

            <button class="btn btn-success" role="button" @click="updateResource">
              <i class="fa fa-save me-2"></i>Update
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End edit modal  -->

    <!-- Start add modal  -->
    <div class="modal fade" id="add-modal" data-bs-keyboard="false" data-bs-backdrop="static" tabindex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">New Supplier</h4>
            </div>
            <div class="p-4">
              <div class="row">
                <div class="col-12">
                  <div class="row pt-0">
                    <div class="mb-2 col-8">
                      <label class="col-form-label" for="recipient-name">Full Name</label>
                      <input class="form-control" id="recipient-name" type="text" placeholder="e.g John Doe"
                        v-model="formData.full_name" :class="v$.formData.full_name.$dirty &&
                          v$.formData.full_name.$invalid
                          ? `is-invalid`
                          : v$.formData.full_name.$dirty
                            ? `is-valid`
                            : ``
                          " />
                      <div v-for="error of v$.formData.full_name.$errors" :key="error.$uid" class="invalid-feedback"
                        style="color: red">
                        This field is invalid
                      </div>
                    </div>
                    <div class="mb-2 col-4">
                      <div class="form-check form-switch mb-0 lh-1">
                        <input class="mx-2 form-check-input" type="checkbox" id="flexSwitchCheckPrimary"
                          :checked="formData.active"  v-model="formData.active"/>
                        <label class="form-check-label mb-0" for="flexSwitchCheckDefault">Active</label>
                      </div>
                    </div>
                    <div class="mb-2 col-6">
                      <label class="col-form-label" for="message-text">Email</label>
                      <input class="form-control" id="recipient-name" type="text" placeholder="e.g john@gmail.com"
                        v-model="formData.email" :class="v$.formData.email.$dirty && v$.formData.email.$invalid
                          ? `is-invalid`
                          : v$.formData.email.$dirty
                            ? `is-valid`
                            : ``
                          " />
                      <div v-for="error of v$.formData.email.$errors" :key="error.$uid" class="invalid-feedback"
                        style="color: red">
                        This field is invalid
                      </div>
                    </div>
                    <div class="mb-2 col-6">
                      <label class="col-form-label" for="message-text">Phone</label>
                      <input class="form-control" id="recipient-home" type="text" placeholder="e.g +25471234567"
                        v-model="formData.phone" :class="v$.formData.phone.$dirty && v$.formData.phone.$invalid
                          ? `is-invalid`
                          : v$.formData.phone.$dirty
                            ? `is-valid`
                            : ``
                          " />
                      <div v-for="error of v$.formData.phone.$errors" :key="error.$uid" class="invalid-feedback"
                        style="color: red">
                        This field is invalid
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label class="col-form-label" for="message-text">Address</label>
                      <textarea v-model="formData.address" class="form-control" id="exampleFormControlTextarea1"
                        rows="3" :placeholder="formData.address"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer mt-2">
            <button class="btn me-auto border" data-bs-dismiss="modal" aria-label="Close">
              Close
            </button>

            <button class="btn btn-success" role="button" @click="saveResource">
              <i class="fa fa-save me-2"></i>Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End add modal  -->
  </div>
</template>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "flatpickr/dist/flatpickr.css";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";
import EasyDataTable from "vue3-easy-data-table";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import SupplierService from "../services/supplier.service";
import { required, email } from "@vuelidate/validators";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import Pagination from "v-pagination-3";
import moment from "moment";

const now = new Date();

export default {
  mixins: [mixins],
  name: "CustomersView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        full_name: { required },
        phone: { required },
        email: { email },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    LoaderOverlay,
    Pagination,
    EasyDataTable,
  },
  data() {
    return {
      per_page: 25,
      current_page: 0,
      records: 0,
      searchValue: "",
      table_keys: 1,
      formData: {
        is_root: 0,
        active: 0,
        show_on_main_page: 0,
      },
      resources: [],
      orders_table_key: 0,
      searchField: ["name", "phone", "email"],
      headers: [
        { text: "#", value: "ID", sortable: true, widh: 10 },
        { text: "Name", value: "NAME", sortable: true },
        { text: "Phone", value: "phone", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "Registered at", value: "created_at", sortable: true },
        { text: "Action", value: "action" },
      ],
    };
  },
  created() {
    this.getData();
  },
  watch: {
    resources(newVal, oldVal) {
      console.log("resource length", newVal.length);
      this.orders_table_key += 1;
    },
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    theme() {
      return localStorage.getItem("theme");
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    clearModals() {
      $("#edit-modal").removeClass("fade").hide();
      $("#add-modal").removeClass("fade").hide();
      $(".modal-backdrop").remove();
    },
    addNew() {
      this.formData = {};
    },
    editItem(item) {
      this.formData = item;
    },
    toggleShowOnMain() {
      this.formData.show_on_main_page = !this.formData.show_on_main_page;
    },
    toggleActive() {
      this.formData.active = !this.formData.active;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    saveResource() {
      this.v$.formData.full_name.$touch();
      this.v$.formData.phone.$touch();
      this.v$.formData.email.$touch();
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      SupplierService.store(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.table_keys += 1;
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
            this.clearModals()
          } else {
            console.group("customer");
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.error(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource() {
      this.v$.formData.full_name.$touch();
      this.v$.formData.phone.$touch();
      this.v$.formData.email.$touch();
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      SupplierService.update(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
            this.clearModals()
          } else {
            this.resources = [];
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteRecource(resource) {
      let sure = confirm("Are you sure you want to delete? you cannot undo this action.");
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        SupplierService.delete(resource.id).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.getData();
              $("#edit-modal" + resource.id)
                .removeClass("fade")
                .hide();
              $(".modal-backdrop").remove();
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    getData() {
      this.$store.commit("SET_LOADING", true);
      return SupplierService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
