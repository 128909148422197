import axios from 'axios'
import authHeader from './auth-header'

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class MenuCategoryService {
    index() {
        return axios.get(VUE_APP_BASE_URL + 'menu-category', { headers: authHeader() });
    }
    store(formData) {
        return axios.post(VUE_APP_BASE_URL + 'menu-category', formData, { headers: authHeader() });
    }
    update(formData) {
        return axios.put(VUE_APP_BASE_URL + 'menu-category/' + formData.id, formData, { headers: authHeader() });
    }
    delete(item) {
        return axios.delete(VUE_APP_BASE_URL + 'menu-category/' + item.id, { headers: authHeader() });
    }
    storeSingle(formData) {
        return axios.post(VUE_APP_BASE_URL + 'menu-category-store', formData, { headers: authHeader() });
    }
}

export default new MenuCategoryService()