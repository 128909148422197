import invoiceService from "../services/invoice.service";

export const invoice = {
  namespaced: true,

  state: {},

  actions: {
    getInvoiceCount({ commit }, data) {
      return invoiceService.getInvoiceCount(data).then((data) => {
        commit("invoice_count", data);
        return Promise.resolve(data);
      });
    },
    getopenInvoice({ commit }, data) {
      return invoiceService.getopenInvoice(data).then((data) => {
        commit("open_invoice", data);
        return Promise.resolve(data);
      });
    },
    getVoidedInvoice({ commit }, data) {
      return invoiceService.getVoidedInvoice(data).then((data) => {
        commit("open_invoice", data);
        return Promise.resolve(data);
      });
    },
    getAllInvoice({ commit }, data) {
      return invoiceService.getAllInvoice(data).then((data) => {
        commit("open_invoice", data);
        return Promise.resolve(data);
      });
    },
    getCloseInvoice({ commit }, data) {
      return invoiceService.getCloseInvoice(data).then((data) => {
        commit("open_invoice", data);
        return Promise.resolve(data);
      });
    },
    payReciptMpesa({ commit }, data) {
      return invoiceService.payReciptMpesa(data).then((data) => {
        commit("pay_receipt_mpesa", data);
        return Promise.resolve(data);
      });
    },
    payInvoice({ commit }, data) {
      return invoiceService.payInvoice(data).then((data) => {
        commit("pay_invoice", data);
        return Promise.resolve(data);
      });
    },
    closeInvoice({ commit }, data) {
      return invoiceService.closeInvoice(data).then((data) => {
        commit("close_invoice", data);
        return Promise.resolve(data);
      });
    },
  },

  mutations: {
    invoice_count(state, data) {
      state.invoice_count = data;
    },
    open_invoice(state, data) {
      state.open_invoice = data;
    },
    pay_receipt_mpesa(state, data) {
      state.pay_receipt_mpesa = data;
    },
    pay_invoice(state, data) {
      state.pay_invoice = data;
    },
    close_invoice(state, data) {
      state.close_invoice = data;
    },
  }
}
