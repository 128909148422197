// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class RestaurantService {
  index() {
    return axios.get(VUE_APP_BASE_URL + "restaurant", {
      headers: authHeader(),
    });
  }
  store(item) {
    return axios.post(VUE_APP_BASE_URL + "restaurant", item, {
      headers: authHeader(),
    });
  }
  update(item) {
    return axios.put(VUE_APP_BASE_URL + "restaurant/" + item.id, item, {
      headers: authHeader(),
    });
  }
  updateLogo(item, config) {
    return axios.post(VUE_APP_BASE_URL + "restaurant-logo-update", item, config);
  }
  delete(item) {
    return axios.delete(VUE_APP_BASE_URL + "restaurant/" + item.id, {
      headers: authHeader(),
    });
  }
}

export default new RestaurantService()