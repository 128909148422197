import ReportService from "../services/report.service";

export const analytics = {
  namespaced: true,

  state: {},

  actions: {
    getDailyAnalysis({ commit }, data) {
      return ReportService.getDailyAnalysis(data).then((data) => {
        commit("daily_analysis", data.data);
        return Promise.resolve(data.data);
      });
    },
    getBestSeller({ commit }, data) {
      return ReportService.getBestSeller(data).then((data) => {
        commit("best_seller", data);
        return Promise.resolve(data);
      });
    },
    getStockAnalysis({ commit }, data) {
      return ReportService.getStockAnalysis(data).then((data) => {
        commit("stock_analysis", data);
        return Promise.resolve(data);
      });
    },
    getGeneralAnalysis({ commit }, data) {
      return ReportService.getGeneralAnalysis(data).then((data) => {
        commit("general_analysis", data);
        return Promise.resolve(data);
      });
    },
  },

  mutations: {
    daily_analysis(state, data) {
      state.daily_analysis = data;
    },
    best_seller(state, data) {
      state.best_seller = data;
    },
    stock_analysis(state, data) {
      state.stock_analysis = data;
    },
    general_analysis(state, data) {
      state.general_analysis = data;
    },
  }
}
