<template>
  <div>
    <div class="row g-2 ps-0">
      <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-header">
            <div class="row justify-content-between">
              <div class="col-md-auto">
                <h5 class="mb-3 mb-md-0">
                  Shopping Cart ({{ cartItems.length }} Items)
                </h5>
              </div>
              <div class="col-md-auto">
                <a
                  class="btn btn-sm btn-falcon-warning border-300 me-2 shadow-none"
                  @click="$router.push('/pos')"
                >
                  <span
                    class="fas fa-chevron-left me-1"
                    data-fa-transform="shrink-4"
                  ></span
                  >Continue</a
                >
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="row gx-card mx-0 bg-200 text-900 fs--1 fw-semi-bold">
              <div class="col-9 col-md-8 py-2">Name</div>
              <div class="col-3 col-md-4">
                <div class="row">
                  <div class="col-md-8 py-2 d-none d-md-block text-center">
                    Quantity
                  </div>
                  <div class="col-12 col-md-4 text-end py-2">Price</div>
                </div>
              </div>
            </div>
            <div
              v-for="(item, i) in cartItems"
              :key="i"
              class="row gx-card mx-0 align-items-center border-bottom border-200"
            >
              <div class="col-8 py-3">
                <div class="d-flex align-items-center">
                  <a 
                    >
                    <img
                    v-if="item.IMAGE"
                    class="rounded-circle"
                    :src="item.IMAGE"
                    alt="Product Image"
                    height="32"
                  />
                  <img
                    v-else
                    class="rounded-circle"
                    src="assets/img/no-image.jpg"
                    alt="Product Image"
                    height="32"
                  />
                </a>
                  <div class="flex-1">
                    <h5 class="fs-0">
                      <a class="text-900">{{
                        item.NAME
                      }}</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-3 col-md-4">
                <div class="row">
                  <div class="col-md-8 py-2 d-none d-md-block text-center">
                    {{ item.QUANTITY }}
                  </div>
                  <div class="col-12 col-md-4 text-end py-2"> {{ formatToCurrency(item.sub_total) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer bg-light d-flex justify-content-end">
            TOTAL {{ formatToCurrency(totalValue) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-background-color: #edf2f9;
  --easy-table-body-row-height: 60px;
  --easy-table-body-row-font-size: 12px;
}
.page-link {
  padding-left: 18px !important;
  padding-right: 18px !important;
}
</style>

<script>
import CountUp from "vue-countup-v3";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Pagination from "v-pagination-3";
import CartService from "../services/cart.service";
import AuthService from "../services/auth.service";
import TablesService from "../services/tables.service";
import TicketService from "../services/ticket.service";
import ProductService from "../services/product.service";
import MenuItemService from "../services/menu_item.service";
import MenuGroupService from "../services/menu_group.service";
import MenuCategoryService from "../services/menu_category.service";
import CustomerService from "../services/customer.service";
import OrderTypeService from "../services/order_types.service";

export default {
  mixins: [mixins],
  components: {
    CountUp,
    Pagination,
  },
  data() {
    return {
      search_term: "",
      list_view: true,
      retail_price: true,
      per_page: 25,
      current_page: 0,
      records: 0,
      checkout: false,
      daily_analysis: [],
      weekly_analysis: [],
      formData: {
        table_id: 1,
        customer_id: "",
        order_type_id: "",
        order_type_name: "",
        cart: {},
        total_amount: 0,
      },
      searchKey: "",
      barcode: "",
      loading_qr: false,
      dialog: false,
      items: {},
      tables: [],
      order_types: [],
      dialogQty: false,
      itemDialog: false,
      productCategories: [],
      customers: [],
      groups: [],
      selectedProductCategory: "",
      cart: {},
      table_selection: localStorage.getItem("table") == null ? true : false,
      category_selection: false,
      order_type_selection: true,
      order_type:
        localStorage.getItem("order_type") == null
          ? {}
          : JSON.parse(localStorage.getItem("order_type")),

      selectedCategory: 0,
      selected_category: "",
      selected_category_data: {},
      selected_group: "",
      selected_table: "",
      selected_customer: "",
    };
  },
  async created() {
    let status = await AuthService.checkAuth();
    if (status == 401) {
      localStorage.removeItem("user");
      alert("Session expired");
      this.$router.push("/login");
    }
    this.$store.commit("SET_LOADING", true);
    this.getAllOrderTypes();
    this.getAllCategories();
    this.getAllGroups();
    // this.getAllTables();
    this.getCustomers();
    this.$store.commit("SET_LOADING", false);
  },
  computed: {
    totalValue() {
      let itemArray = this.$store.state.pos.cart;
      let totalValue = 0;
      itemArray.forEach((element) => {
        totalValue =
          totalValue + parseFloat(element.QUANTITY) * parseFloat(element.PRICE);
      });
      return parseFloat(totalValue);
    },
    cartLength() {
      let data = this.cart;
      if (data.length) {
        return data.length;
      } else {
        return "empty";
      }
    },
    cartItems() {
      return this.$store.state.pos ? this.$store.state.pos.cart : [];
    },
    table() {
      return localStorage.getItem("table") == null ? {} : this.selected_table;
    },
    category() {
      return this.selected_category_data;
    },
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
    institute() {
      return JSON.parse(localStorage.getItem("institute"));
    },
  },
  methods: {
    toggleRetail() {
      console.log(this.retail_price);
      this.retail_price = !this.retail_price;
    },
    toggleView() {
      this.list_view = !this.list_view;
    },
    selectGroup(e) {
      this.selected_group = e;
      let formData = {
        menu_group_id: e,
      };
      this.menuList(formData, 1);
    },
    searchItem() {
      this.page = 1;
      let formData = {
        search_term: this.search_term,
      };
      this.$store.commit("SET_LOADING", true);
      return MenuItemService.menuSearch(formData, this.current_page).then(
        (response) => {
          if (response.data.code == 200) {
            this.items = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    deleteItem(item) {
      this.cart.splice(this.cart.indexOf(item), 1);
    },
    editItem(item, quantity) {
      if (quantity == 0) {
        this.cart.splice(this.cart.indexOf(item), 1);
      }
      let myCart = this.cart ? Object.values(this.cart) : [];
      if (myCart.includes(item)) {
        let existingElement = myCart.find((element) => element.id == item.id);
        existingElement.quantity = quantity;
        existingElement.sub_total = quantity * item.price;
      }
      this.cart = myCart;
      toast.success("1 " + item.NAME + " updated in cart");
    },
    truncateText(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    toggleCheckout(val) {
      this.checkout = val;
    },
    formatToCurrency: function (amount) {
      return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    sortItems(list) {
      return list.sort((a, b) => (a.NAME > b.NAME ? 1 : -1));
    },
    getTimegreetings() {
      const time = new Date().getHours();
      let greetings;
      if (time < 12) greetings = "Good Morning";
      else if (time >= 12 && time <= 17) greetings = "Good Afternoon";
      else greetings = "Good Evening";
      return greetings;
    },
    tenderedInput() {
      // this.formData.balance_amount =  this.formData.tender_amount;
      this.formData.balance_amount =
        parseFloat(this.formData.tender_amount) - parseFloat(this.totalValue);
    },
    payMpesa() {
      console.log(this.cartLength);
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      // Custome for cash
      this.formData.settled = 1;
      this.formData.payment_method = "MPESA";
      // End
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.id;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.id;
      this.formData.order_type_name = this.order_type.name;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Sale placed!");
            this.cart = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    payCash() {
      console.log(this.cartLength);
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      // Custome for cash
      this.formData.settled = 1;
      this.formData.payment_method = "CASH";
      // End
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.id;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.id;
      this.formData.order_type_name = this.order_type.name;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Sale placed!");
            this.cart = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },

    completeCheckout() {
      this.preview_cash_modal = false;
      this.preview_dc_modal = false;
      this.digital_change = false;
      this.change_sent = false;
      this.qrData = {};
      this.clearCart();
      this.$notify({
        group: "foo",
        type: "success",
        title: "Checkout complete",
        text: "order places and paid successfuly!",
      });
    },
    async categorySelected(cat) {
      this.selected_category_data = cat;
      this.selected_category = cat.id;
      this.getMenuGroupsByCategory(cat.id);
    },
    getMenuGroupsByCategory(selected_category) {
      let formData = {
        menu_category: selected_category,
      };
      return ProductService.getMenuGroupsByCategory(formData).then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
            if (response.data.data.length > 0) {
              let formData = {
                menu_group_id: this.groups.at(0).id,
              };
              this.menuList(formData, 1);
            }
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },

    orderTicket() {
      console.log(this.cartLength);
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.id;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.id;
      this.formData.order_type_name = this.order_type.name;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Order placed!");
            this.cart = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    clearCart() {
      this.cart = {};
      toast.info("Cart cleared!");
    },
    goToMyOrders() {
      this.$router.push("/my-orders");
    },
    goToAllOrders() {
      this.$router.push("/pos-open-order");
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    menuList(formData, page) {
      this.$store.commit("SET_LOADING", true);
      return MenuItemService.menuList(formData, page).then(
        (response) => {
          if (response.data.code == 200) {
            console.group("resource");
            console.log(response.data.data);
            this.items = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getItemsByOrder(item) {
      this.$store.commit("SET_LOADING", true);
      return CartService.byOrder(item.id).then(
        (response) => {
          if (response.data.code == 200) {
            console.group("resource");
            console.log(response.data.data);
            this.items = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    myCallback(e) {
      let formData = {};
      if (this.selected_group) {
        formData.menu_group_id = this.selected_group;
      }
      if (this.order_type.id) {
        formData.order_type_id = this.order_type.id;
      }
      if (this.search_term) {
        this.searchItem();
      } else {
        this.menuList(formData, e);
      }
    },
    getAll() {
      return CartService.getAll().then(
        (response) => {
          if (response.data.code == 200) {
            this.items = response.data.data.data;
            this.current_page = response.data.data.current_page;
            this.$store.commit("SET_LOADING", false);
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllTables() {
      this.$store.commit("SET_LOADING", true);
      return TablesService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.tables = response.data.data;
            if (this.tables.length > 0) this.selected_table = this.tables.at(0);
            this.$store.commit("SET_LOADING", falses);
          } else {
            console.log(response.data.error);
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getAllGroups() {
      this.$store.commit("SET_LOADING", true);
      return MenuGroupService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.groups = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getAllCategories() {
      this.$store.commit("SET_LOADING", true);
      return MenuCategoryService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.productCategories = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getAllOrderTypes() {
      return OrderTypeService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.order_types = response.data.data;
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuGroupsByOrder() {
      let formData = {
        order_type: this.order_type.id,
      };
      return ProductService.getMenuGroupsByOrder(formData).then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
            this.selected_group = response.data.data.at(0).id;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getCustomers() {
      // samdoh
      return CustomerService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.customers = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllProducts() {
      return ProductService.getAll().then(
        (response) => {
          if (response.data.status == "success") {
            this.items = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuCategoryByOrder(order_type) {
      if (this.order_types.length > 0) {
        this.formData.order_type = order_type;
        return ProductService.getMenuCategoryByOrder(this.formData).then(
          (response) => {
            if (response.data.code == 200) {
              this.productCategories = response.data.data;
            } else {
              console.log(response.data.error);
            }
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      } else {
        this.productCategories = [];
      }
    },
    addTocart(item) {
      console.log("Adding to cart...");
      // this.$store.dispatch("pos/addToCart", item);
      let myCart = this.cart ? Object.values(this.cart) : [];
      if (myCart.includes(item)) {
        let existingElement = myCart.find((element) => element.id == item.id);
        item.sub_total = item.quantity * item.price;
        existingElement.quantity = existingElement.quantity + 1;
        existingElement.sub_total =
          existingElement.sub_total + item.quantity * item.price;
      } else {
        item.quantity = 1;
        item.sub_total = item.quantity * item.price;
        myCart.push(item);
      }
      this.cart = myCart;
      localStorage.setItem("cart", JSON.stringify(this.cart));
      // toast.info("1 " + item.NAME + " added to cart!", {
      //   autoClose: 2000,
      // });
    },
    selectTable(item) {
      this.table_selection = false;
      localStorage.setItem("table", JSON.stringify(item));
      this.$notify({
        group: "foo",
        type: "info",
        title: "Selection",
        text: "Table selected!",
      });
    },
    selectOrderType(item) {
      this.order_type = item;
      this.table_selection = true;
      this.order_type_selection = false;
      localStorage.setItem("order_type", JSON.stringify(item));
      let formData = {
        order_type_id: item.id,
      };
      this.menuList(formData, 1);
      // get menu category bu order type
      this.getMenuCategoryByOrder(item.id);
    },
  },
};
</script>
