
import moment from "moment";

function formatDate(input) {

    return moment(input).format("DD-MM-YYYY HH:mm:ss");
}
function formatDateInvoice(input) {

    return moment(input).format("DD/MM/YYYY");
}


export default{formatDate,formatDateInvoice}