import { createStore } from 'vuex'
import { auth } from './auth.module'
import { alert } from './alert.module'
import { pos } from './pos.module'
import { invoice} from './invoice.module'
import { analytics} from './analytics.module'

export default createStore({
  state: {
    loading: false,
    notifications: [],
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getNotifications(state) {
      return state.notifications;
    },
  },
  mutations: {
    SET_LOADING(state, val) {
      this.state.loading = val;
    },
    SET_NOTIFICATIONS(state, val) {
      this.state.notifications = val;
    },
  },
  actions: {},
  modules: {
    auth,
    alert,
    pos,
    invoice,
    analytics,
  },
});
