<template>
  <div>
    <div
      class="card mb-3"
      id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
    >
      <div class="card-header">
        <div class="row flex-between-center border-bottom pb-1">
          <div class="col-4 col-sm-auto align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              <i class="fa fa-shopping-cart text-warning"></i>
              Menu Sizes
            </h5>
            <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
              Manage all available menu sizes
            </p>
          </div>
          <div class="col-8 col-sm-auto ms-auto text-end ps-0">
            <div class="d-none" id="orders-bulk-actions">
              <div class="d-flex">
                <select
                  class="form-select form-select-sm"
                  aria-label="Bulk actions"
                >
                  <option selected="">Bulk actions</option>
                  <option value="Refund">Refund</option>
                  <option value="Delete">Delete</option>
                  <option value="Archive">Archive</option></select
                ><button
                  class="btn btn-falcon-default btn-sm ms-2"
                  type="button"
                >
                  Apply
                </button>
              </div>
            </div>
            <div id="orders-actions" class="d-flex">
              <div class="search-box h1">
                <input
                  class="form-control search-input fuzzy-search"
                  type="text"
                  placeholder="Search..."
                  aria-label="Search"
                  v-model="searchValue"
                />
                <span class="fas fa-search search-box-icon"></span>
              </div>

              <button
                class="btn btn-falcon-warning btn-sm ms-2 rounded-pil h1"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#add-modal"
              >
                <span class="fas fa-plus" data-fa-transform=""></span
                ><span class="d-none d-sm-inline-block ms-1">New</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body px-3 py-0">
        <!-- Menu items table  -->
        <EasyDataTable
          v-if="theme == 'dark'"
          id="tour-step-1"
          table-class-name="dark-table"
          alternating
          buttons-pagination
          :headers="headers"
          :items="resources"
          :search-field="searchField"
          :search-value="searchValue"
          :loading="loading"
        >
          <template #loading>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
          <template #item-name="item">
            <div class="my-2">
              <strong>{{ item.name.toUpperCase() }}</strong>
              <p class="mb-0 text-500 text-wrap">{{ item.email }}</p>
            </div>
          </template>
          <template #item-created_at="item">
            <div class="my-2">
              {{ formatDateHuman(item.created_at) }}
            </div>
          </template>

          <template #item-action="item">
            <div class="dropdown font-sans-serif position-static">
              <button
                class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="order-dropdown-0"
                data-bs-toggle="dropdown"
                data-boundary="viewport"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="fas fa-ellipsis-h fs--1"></span>
              </button>
              <div
                class="dropdown-menu dropdown-menu-end border py-0"
                aria-labelledby="order-dropdown-0"
              >
                <div class="py-2">
                  <a
                    class="dropdown-item"
                    role="button"
                    data-bs-toggle="modal"
                    :data-bs-target="'#edit-modal' + item.id"
                    >Edit</a
                  >

                  <div class="dropdown-divider"></div>
                  <a
                    class="dropdown-item text-danger"
                    role="button"
                    @click="deleteRecource(item)"
                    >Delete</a
                  >
                </div>
              </div>
            </div>
          </template>
        </EasyDataTable>
        <EasyDataTable
          v-else
          id="tour-step-1"
          table-class-name="customize-table"
          alternating
          buttons-pagination
          :headers="headers"
          :items="resources"
          :search-field="searchField"
          :search-value="searchValue"
          :loading="loading"
        >
          <template #loading>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
          <template #item-name="item">
            <div class="my-2">
              <strong>{{ item.name.toUpperCase() }}</strong>
              <p class="mb-0 text-500 text-wrap">{{ item.email }}</p>
            </div>
          </template>
          <template #item-created_at="item">
            <div class="my-2">
              {{ formatDateHuman(item.created_at) }}
            </div>
          </template>

          <template #item-action="item">
            <div class="dropdown font-sans-serif position-static">
              <button
                class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="order-dropdown-0"
                data-bs-toggle="dropdown"
                data-boundary="viewport"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="fas fa-ellipsis-h fs--1"></span>
              </button>
              <div
                class="dropdown-menu dropdown-menu-end border py-0"
                aria-labelledby="order-dropdown-0"
              >
                <div class="py-2">
                  <a
                    class="dropdown-item"
                    role="button"
                    data-bs-toggle="modal"
                    :data-bs-target="'#edit-modal' + item.id"
                    >Edit</a
                  >

                  <div class="dropdown-divider"></div>
                  <a
                    class="dropdown-item text-danger"
                    role="button"
                    @click="deleteRecource(item)"
                    >Delete</a
                  >
                </div>
              </div>
            </div>
          </template>
        </EasyDataTable>
        <!-- End Menu items table  -->
      </div>
      <div class="card-footer">
        <!-- footer content  -->
      </div>
    </div>
    <!-- Start edit modal  -->
    <div v-for="(resource, i) in resources" :key="i">
      <div
        class="modal fade"
        :id="'edit-modal' + resource.id"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        tabindex="-1"
        :aria-labelledby="'edit-modal' + resource.id + 'Label'"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg mt-6" role="document">
          <div class="modal-content border-0">
            <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
              <button
                id="edit-modal-close"
                class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-0">
              <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                <h4 class="mb-1" id="staticBackdropLabel">Edit size</h4>
              </div>
              <div class="row my-3 mx-2">
                <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                  <div class="d-flex">
                    <div class="flex-1">
                      <div class="row">
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <label class="col-form-label" for="recipient-name"
                            >Name</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            :placeholder="resource.name"
                            v-model="formData.name"
                            :class="
                              v$.formData.name.$dirty &&
                              v$.formData.name.$invalid
                                ? `is-invalid`
                                : v$.formData.name.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.name.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <label class="col-form-label" for="message-text"
                            >Translated name</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            :placeholder="resource.translated_name"
                            v-model="formData.translated_name"
                            :class="
                              v$.formData.translated_name.$dirty &&
                              v$.formData.translated_name.$invalid
                                ? `is-invalid`
                                : v$.formData.translated_name.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.translated_name.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                      </div>
                      <hr class="my-2" />
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-1">
                      <h5 class="mb-2 fs-0">Optional fields</h5>
                      <div class="row">
                        <div
                          class="mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        >
                          <label class="col-form-label" for="message-text"
                            >Sort order</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            :placeholder="resource.sort_order"
                            v-model="formData.sort_order"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                  <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                  <ul class="nav flex-lg-column fs--1">
                    <li class="nav-item me-2 me-lg-0 my-2">
                      <a
                        @click="toggleActive"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="resource.visible ? `bg-info text-white` : ``"
                        ><span class="fa fa-check me-2"></span>Active?
                      </a>
                    </li>
                    <br />
                    <hr />
                    <br />
                    <br />
                    <li class="nav-item me-2 me-lg-0 mt-8">
                      <a
                        class="nav-link nav-link-card-details bg-danger text-white"
                        role="button"
                        @click="deleteRecource(resource)"
                        ><span class="fas fa-paperclip me-2"></span>Delete</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block"
                type="button"
                @click="updateResource(resource)"
              >
                <span class="fa fa-save me-2" data-fa-transform=""></span
                ><span class="d-none d-sm-inline-block ms-1">Update size</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End edit modal  -->

    <!-- Start add modal  -->
    <div
      class="modal fade"
      id="add-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              id="add-modal-close"
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Add new size</h4>
            </div>
            <div class="p-4">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-lg-9 col-xl-9">
                  <div class="d-flex">
                    <div class="flex-1">
                      <div class="row">
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <label class="col-form-label" for="recipient-name"
                            >Name</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.g UGALI BEEF"
                            v-model="formData.name"
                            :class="
                              v$.formData.name.$dirty &&
                              v$.formData.name.$invalid
                                ? `is-invalid`
                                : v$.formData.name.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.name.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <label class="col-form-label" for="message-text"
                            >Translated name</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="Another name..."
                            v-model="formData.translated_name"
                            :class="
                              v$.formData.translated_name.$dirty &&
                              v$.formData.translated_name.$invalid
                                ? `is-invalid`
                                : v$.formData.translated_name.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.translated_name.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                      </div>
                      <hr class="my-2" />
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-1">
                      <h5 class="mb-2 fs-0">Optional fields</h5>
                      <div class="row">
                        <div
                          class="mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        >
                          <label class="col-form-label" for="message-text"
                            >Sort order</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.ge 123"
                            v-model="formData.sort_order"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                  <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                  <ul class="nav flex-lg-column fs--1">
                    <li class="nav-item me-2 me-lg-0 my-2">
                      <a
                        @click="toggleActive"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="formData.visible ? `bg-info text-white` : ``"
                        ><span class="fa fa-check me-2"></span>Active?
                      </a>
                    </li>
                    <br />
                    <hr />
                    <br />
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block"
              type="button"
              @click="saveResource"
            >
              <span class="fa fa-save me-2" data-fa-transform=""></span
              ><span class="d-none d-sm-inline-block ms-1">Save size</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End add modal  -->
  </div>
</template>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "flatpickr/dist/flatpickr.css";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";
import EasyDataTable from "vue3-easy-data-table";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import { required, email } from "@vuelidate/validators";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import MenuItemSizeService from "../services/menu_item_size.service";

const now = new Date();

export default {
  mixins: [mixins],
  name: "CustomersView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        name: { required },
        translated_name: { required },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      menu_item_sizes: [],
      groups: [],
      searchValue: "",
      table_keys: 1,
      formData: {
        group_id: "",
        active: 0,
        visible: 1,
        unit_name: "",
      },
      resources: [],
      orders_table_key: 0,
      searchField: ["name", "mobile_no", "email"],
      headers: [
        { text: "#", value: "id", sortable: true, widh: 10 },
        { text: "Name", value: "name", sortable: true },
        { text: "Translated name", value: "translated_name", sortable: true },
        { text: "Action", value: "action" },
      ],
    };
  },
  created() {
    // this.forceReload();
    this.getData();
    // this.getGroups();
    // this.getMenuItemSize();
  },
  watch: {
    resources(newVal, oldVal) {
      console.log("resource length", newVal.length);
      this.orders_table_key += 1;
    },
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    theme() {
      return localStorage.getItem("theme");
    },
  },
  methods: {
    hideModals() {
      $("#add-modal-close").click();
      $("#edit-modal-close").click();
    },
    groupSelected(e) {
      this.formData.group_id = e.target.value;
    },
    getMenuItemSize() {
      return MenuItemSizeService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.menu_item_sizes = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getGroups() {
      return MenuItemSizeService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    toggleShowOnMain() {
      this.formData.show_on_main_page = !this.formData.show_on_main_page;
    },
    toggleActive() {
      this.formData.active = !this.formData.active;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    saveResource() {
      this.v$.formData.name.$touch();
      this.v$.formData.translated_name.$touch();
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      MenuItemSizeService.store(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.table_keys += 1;
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
            this.hideModals();
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.error(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource(resource) {
      this.$store.commit("SET_LOADING", true);
      this.formData.id = resource.id;
      if (!this.formData.group_id) {
        this.formData.group_id = resource.group_id;
      }
      if (!this.formData.visible) {
        this.formData.visible = resource.visible;
      }
      if (!this.formData.price) {
        this.formData.price = resource.price;
      }
      if (!this.formData.translated_name) {
        this.formData.translated_name = resource.translated_name;
      }
      if (!this.formData.sort_order) {
        this.formData.sort_order = resource.sort_order;
      }
      if (!this.formData.unit_name) {
        this.formData.unit_name = resource.unit_name;
      }
      if (!this.formData.description) {
        this.formData.description = resource.description;
      }
      if (!this.formData.name) {
        this.formData.name = resource.name;
      }
      MenuItemSizeService.update(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
            this.formData = {
              group_id: "",
              active: 0,
              visible: 1,
              unit_name: "",
              sort_order: "",
              name: "",
              description: "",
              translated_name: "",
              price: "",
              stock_amount: "",
            };
            // Clear modals
            this.hideModals();
          } else {
            this.resources = [];
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteRecource(resource) {
      let sure = confirm(
        "Are you sure you want to delete? you cannot undo this action."
      );
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        MenuItemSizeService.delete(resource).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.getData();
              $("#edit-modal" + resource.id)
                .removeClass("fade")
                .hide();
              $(".modal-backdrop").remove();
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    getData() {
      this.$store.commit("SET_LOADING", true);
      return MenuItemSizeService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
