<template>
  <div>
    <div class="row g-3 mb-3">
      <div class="col-12 col-xl-12">
        <div class="row g-3">
          <div class="col-12">
            <div class="card bg-transparent-50 overflow-hidden">
              <div class="card-header position-relative">
                <div
                  class="bg-holder d-none d-md-block bg-card z-index-1"
                  style="
                    background-image: url('../assets/img/illustrations/ecommerce-bg.png');
                    background-size: 230px;
                    background-position: right bottom;
                    z-index: -1;
                  "
                ></div>
                <!--/.bg-holder-->
                <div class="position-relative z-index-2">
                  <div>
                    <h5 class="text-primary">
                      {{ getTimegreetings() }}, {{ user.first_name }}!
                    </h5>
                    <p>Configure your store here!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3 mx-2">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="mb-0">Website Settings</h5>
                </div>
                <div class="col-auto">
                  <!-- <a
                    class="btn btn-falcon-default btn-sm"
                    href="customer-details.html#!"
                    ><span class="fas fa-pencil-alt fs--2 me-1"></span>Update
                    details</a
                  > -->
                </div>
              </div>
            </div>
            <div class="card-body bg-light border-top">
              <div class="row">
                <div class="col-lg col-xxl-5">
                  <h6 class="fw-semi-bold ls mb-3 text-uppercase">Business Settings</h6>
                  <hr />
                  <div class="row mb-3">
                    <div class="col-4 col-sm-4 mt-1">
                      <p class="fw-semi-bold mb-1">Business Name</p>
                    </div>
                    <div class="col">
                      <input
                        class="form-control"
                        id="exampleFormControlInput1"
                        type="text"
                        placeholder=""
                        v-model="formData.name"
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-4 col-sm-4 mt-1">
                      <p class="fw-semi-bold mb-1">Business Category</p>
                    </div>
                    <div class="col">
                      <select
                        class="form-select"
                        aria-label="Select size"
                        v-model="formData.type"
                      >
                        <option value="restaurant" selected>Restaurant/Lounge/Bar</option>
                        <option value="pharmacy">Pharmacy</option>
                        <option value="supermarket">Supermarket</option>
                        <option value="retail">Retail Shop</option>
                        <option value="hardware">Hardware Shop</option>
                        <option value="barber">Barber Shop</option>
                        <option value="salon">Salon/SPA</option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4 col-sm-4 mt-1">
                      <p class="fw-semi-bold mb-1">First Address</p>
                    </div>
                    <div class="col">
                      <input
                        class="form-control"
                        id="exampleFormControlInput1"
                        type="text"
                        placeholder=""
                        v-model="formData.address_line1"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4 col-sm-4 mt-1">
                      <p class="fw-semi-bold mb-1">Second Address</p>
                    </div>
                    <div class="col">
                      <input
                        class="form-control"
                        id="exampleFormControlInput1"
                        type="text"
                        placeholder=""
                        v-model="formData.address_line2"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4 col-sm-4 mt-1">
                      <p class="fw-semi-bold mb-1">Third Address</p>
                    </div>
                    <div class="col">
                      <input
                        class="form-control"
                        id="exampleFormControlInput1"
                        type="text"
                        placeholder=""
                        v-model="formData.address_line3"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4 col-sm-4 mt-1">
                      <p class="fw-semi-bold mb-1">Zip Code</p>
                    </div>
                    <div class="col">
                      <input
                        class="form-control"
                        id="exampleFormControlInput1"
                        type="text"
                        placeholder=""
                        v-model="formData.zip_code"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4 col-sm-4 mt-1">
                      <p class="fw-semi-bold mb-1">Phone</p>
                    </div>
                    <div class="col">
                      <input
                        class="form-control"
                        id="exampleFormControlInput1"
                        type="text"
                        placeholder=""
                        v-model="formData.telephone"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4 col-sm-4 mt-1">
                      <p class="fw-semi-bold mb-1">Tables</p>
                    </div>
                    <div class="col">
                      <input
                        class="form-control"
                        id="exampleFormControlInput1"
                        type="text"
                        placeholder=""
                        v-model="formData.tables"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4 col-sm-4 mt-1">
                      <p class="fw-semi-bold mb-1">Capacity</p>
                    </div>
                    <div class="col">
                      <input
                        class="form-control"
                        id="exampleFormControlInput1"
                        type="text"
                        placeholder=""
                        v-model="formData.capacity"
                      />
                    </div>
                  </div>

                </div>
                <div class="col-lg col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                  <h6 class="fw-semi-bold ls mb-3 text-uppercase">Billing Information</h6>
                  <hr />

                  <div class="row mb-3">
                    <div class="col-12 text-center">
                      <div
                        class="mt-n7 mb-7 position-relative avatar avatar-5xl avatar-profile shadow-sm img-thumbnail rounded-circle"
                      >
                        <div
                          class="h-100 w-100 rounded-circle overflow-hidden position-relative"
                        >
                          <img
                            :src="formData.logo"
                            width="300"
                            alt=""
                            data-dz-thumbnail="data-dz-thumbnail"
                          /><input
                            @change="onFileChange"
                            class="d-none"
                            id="profile-image"
                            type="file"
                          /><label
                            class="mb-0 overlay-icon d-flex flex-center"
                            for="profile-image"
                            ><span class="bg-holder overlay overlay-0"></span
                            ><span
                              class="z-index-1 text-white dark__text-white text-center fs--1"
                              ><span class="fas fa-camera"></span
                              ><span class="d-block">Update</span></span
                            ></label
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div class="row mb-3">
                    <div class="col-6 col-sm-6 mt-1">
                      <div class="form-check form-switch mb-0 lh-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckPrimary"
                          :checked="formData.separate_inventory_items"
                          v-model="formData.separate_inventory_items"
                        /><label
                          class="form-check-label mb-0"
                          for="flexSwitchCheckDefault"
                          >Separate inventory items?</label
                        >
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-check form-switch mb-0 lh-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckPrimary"
                          :checked="formData.allow_price_edits"
                          v-model="formData.allow_price_edits"
                        /><label
                          class="form-check-label mb-0"
                          for="flexSwitchCheckDefault"
                          >Allow price edit on sale?</label
                        >
                      </div>
                    </div>
                  </div>
                  
                  <div class="row mb-3">
                    <div class="col-6 col-sm-6 mt-1">
                      <div class="form-check form-switch mb-0 lh-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckPrimary"
                          :checked="formData.running"
                          v-model="formData.running"
                        /><label
                          class="form-check-label mb-0"
                          for="flexSwitchCheckDefault"
                          >Running?</label
                        >
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-check form-switch mb-0 lh-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckPrimary"
                          :checked="formData.show_item_buying_price"
                          v-model="formData.show_item_buying_price"
                        /><label
                          class="form-check-label mb-0"
                          for="flexSwitchCheckDefault"
                          >Show Buying Price?</label
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-6 col-sm-6 mt-1">
                      <div class="form-check form-switch mb-0 lh-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckPrimary"
                          :checked="formData.single_location"
                          v-model="formData.single_location"
                        /><label
                          class="form-check-label mb-0"
                          for="flexSwitchCheckDefault"
                          >Single Location?</label
                        >
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-check form-switch mb-0 lh-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckPrimary"
                          :checked="formData.show_item_quantity"
                          v-model="formData.show_item_quantity"
                        /><label
                          class="form-check-label mb-0"
                          for="flexSwitchCheckDefault"
                          >Show Quantity on item</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-6 col-sm-6 mt-1">
                      <div class="form-check form-switch mb-0 lh-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckPrimary"
                          :checked="formData.allow_negative_sale"
                          v-model="formData.allow_negative_sale"
                        /><label
                          class="form-check-label mb-0"
                          for="flexSwitchCheckDefault"
                          >Allow negative sales?</label
                        >
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-check form-switch mb-0 lh-1">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckPrimary"
                          :checked="formData.suspend"
                          v-model="formData.suspend"
                        /><label
                          class="form-check-label mb-0"
                          for="flexSwitchCheckDefault"
                          >Suspend Account</label
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-4 col-sm-4 mt-1">
                      <p class="fw-semi-bold mb-1">Ticket Footer</p>
                    </div>
                    <div class="col">
                      <input
                        class="form-control"
                        id="exampleFormControlInput1"
                        type="text"
                        placeholder=""
                        v-model="formData.ticket_footer"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-4 col-sm-4">
                      <p class="fw-semi-bold mb-1">Website URL</p>
                    </div>
                    <div class="col">
                      <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon3">https://</span>
                        <input
                          v-model="formData.domain"
                          class="form-control"
                          id="basic-url"
                          type="text"
                          aria-describedby="basic-addon3"
                        />
                        <span class="input-group-text" id="basic-addon3"
                          >.bulla.co.ke</span
                        >
                      </div>
                    </div>
                  </div>
                  

                  <div class="row mb-3 border-top">
                    <div class="col-12 mt-1">
                      <p class="fw-semi-bold mb-1">POS Buttons</p>
                    </div>
                    <div class="col-12 d-flex">
                      <button
                        @click="showOrderBtn"
                        class="btn"
                        :class="
                          formData.show_order_on_pos
                            ? `btn-primary`
                            : `btn-falcon-primary`
                        "
                      >
                        <i class="fa fa-cart-plus me-2 mt-1"></i><br />Order
                      </button>
                      <button
                        @click="showMpesaBtn"
                        class="btn ms-2"
                        :class="
                          formData.show_mpesa_on_pos
                            ? `btn-primary`
                            : `btn-falcon-primary`
                        "
                      >
                        <i class="fa fa-credit-card me-2 mt-1"></i><br />MPESA
                      </button>
                      <button
                        @click="showCashBtn"
                        class="btn ms-2"
                        :class="
                          formData.show_cash_on_pos ? `btn-primary` : `btn-falcon-primary`
                        "
                      >
                        <i class="fa fa-check-square me-2 mt-1"></i><br />CASH
                      </button>
                      <button
                        @click="showCardBtn"
                        class="btn ms-2"
                        :class="
                          formData.show_card_on_pos ? `btn-primary` : `btn-falcon-primary`
                        "
                      >
                        <i class="fa fa-check-square me-2 mt-1"></i><br />CARD
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer border-top text-end">
              <!-- <a
                class="btn btn-falcon-default btn-sm"
                href="customer-details.html#!"
                ><span class="fas fa-dollar-sign fs--2 me-1"></span>Refund</a
              > -->
              <button class="btn btn-falcon-success btn-sm ms-2" @click="updateResource">
                <span class="fas fa-save fs--2 me-1"></span>Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="authentication-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="authentication-modal-label"
      aria-hidden="true"
    >
      <div class="modal-dialog mt-6" role="document">
        <div class="modal-content border-0">
          <div class="modal-header px-5 position-relative modal-shape-header bg-shape">
            <div class="position-relative z-index-1 light">
              <h4 class="mb-0 text-white" id="authentication-modal-label">Register</h4>
              <p class="fs--1 mb-0 text-white">Please create your free Falcon account</p>
            </div>
            <button
              class="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body py-4 px-5">
            <form>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-name">Name</label
                ><input
                  class="form-control"
                  type="text"
                  autocomplete="on"
                  id="modal-auth-name"
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-email">Email address</label
                ><input
                  class="form-control"
                  type="email"
                  autocomplete="on"
                  id="modal-auth-email"
                />
              </div>
              <div class="row gx-2">
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-password">Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-password"
                  />
                </div>
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-confirm-password"
                    >Confirm Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-confirm-password"
                  />
                </div>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="modal-auth-register-checkbox"
                /><label class="form-label" for="modal-auth-register-checkbox"
                  >I accept the <a href="index.html#!">terms </a>and
                  <a href="index.html#!">privacy policy</a></label
                >
              </div>
              <div class="mb-3">
                <button
                  class="btn btn-primary d-block w-100 mt-3"
                  type="submit"
                  name="submit"
                >
                  Register
                </button>
              </div>
            </form>
            <div class="position-relative mt-5">
              <hr />
              <div class="divider-content-center">or register with</div>
            </div>
            <div class="row g-2 mt-2">
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-google-plus btn-sm d-block w-100"
                  href="index.html#"
                  ><span
                    class="fab fa-google-plus-g me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  google</a
                >
              </div>
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-facebook btn-sm d-block w-100"
                  href="index.html#"
                  ><span
                    class="fab fa-facebook-square me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  facebook</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Start new usage modal  -->
    <div
      class="modal fade"
      id="register-usage-modal"
      :key="modals_key"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="max-width: 500px"
      >
        <div class="modal-content position-relative">
          <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="rounded-top-lg py-2 ps-4 pe-6 bg-light">
              <h4 class="mb-1" id="modalExampleDemoLabel">Add a new usage</h4>
            </div>
            <div class="px-4 pb-0">
              <form>
                <div class="mb-3">
                  <label class="col-form-label" for="recipient-name">Product Name:</label>
                  <div
                    class="form-control"
                    id="recipient-name"
                    type="text"
                    :class="
                      v$.formData.product_id.$dirty && v$.formData.product_id.$invalid
                        ? `is-invalid`
                        : v$.formData.product_id.$dirty
                        ? `is-valid`
                        : ``
                    "
                  >
                    <select
                      ref="choicejs1"
                      class="form-select bg-danger is-invalid"
                      id="organizerSingle"
                      size="1"
                      name="organizerSingle"
                      data-options='{"removeItemButton":true,"placeholder":true}'
                      @change="itemSelected"
                    >
                      <option value="">Select product...</option>
                    </select>
                  </div>
                  <div
                    v-for="error of v$.formData.product_id.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    Oops! This field is invalid.
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-6">
                    <label class="col-form-label" for="recipient-name">Quantity:</label>
                    <input
                      class="form-control"
                      id="recipient-name"
                      type="text"
                      v-model="formData.quantity"
                      :class="
                        v$.formData.quantity.$dirty && v$.formData.quantity.$invalid
                          ? `is-invalid`
                          : v$.formData.quantity.$dirty
                          ? `is-valid`
                          : ``
                      "
                    />
                    <div
                      v-for="error of v$.formData.quantity.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! This field is invalid.
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="col-form-label" for="recipient-name">Size:</label>
                    <select
                      class="form-select"
                      aria-label="Select size"
                      @change="sizeSelected"
                      :class="
                        v$.formData.size_id.$dirty && v$.formData.size_id.$invalid
                          ? `is-invalid`
                          : v$.formData.size_id.$dirty
                          ? `is-valid`
                          : ``
                      "
                    >
                      <option value="" selected>Select...</option>
                      <option
                        selected=""
                        v-for="(item, i) in item_sizes"
                        :key="i"
                        :value="item.id"
                      >
                        {{ item.title }}
                      </option>
                    </select>
                    <div
                      v-for="error of v$.formData.size_id.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! This field is invalid.
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <label class="col-form-label" for="message-text">Message:</label>
                  <textarea class="form-control" id="message-text"></textarea>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer mx-2">
            <button
              class="btn btn-secondary float-left"
              type="button"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button class="btn btn-warning ms-auto" type="button" @click="reduceStock">
              Reduce stock
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End new usage modal  -->
    <!-- Start restock modal  -->
    <div
      class="modal fade"
      id="restock-modal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="max-width: 500px"
      >
        <div class="modal-content position-relative">
          <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="rounded-top-lg py-2 ps-4 pe-6 bg-light">
              <h4 class="mb-1" id="modalExampleDemoLabel">Add a new usage</h4>
            </div>
            <div class="px-4 pb-0">
              <form>
                <div class="mb-3">
                  <label class="col-form-label" for="recipient-name">Product Name:</label>
                  <div
                    class="form-control"
                    id="recipient-name"
                    type="text"
                    :class="
                      v$.formData.product_id.$dirty && v$.formData.product_id.$invalid
                        ? `is-invalid`
                        : v$.formData.product_id.$dirty
                        ? `is-valid`
                        : ``
                    "
                  >
                    <select
                      ref="choicejs2"
                      class="form-select bg-danger is-invalid"
                      id="organizerSingle"
                      size="1"
                      name="organizerSingle"
                      data-options='{"removeItemButton":true,"placeholder":true}'
                      @change="itemSelected"
                    >
                      <option value="">Select product...</option>
                    </select>
                  </div>
                  <div
                    v-for="error of v$.formData.product_id.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    Oops! This field is invalid.
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-6">
                    <label class="col-form-label" for="recipient-name">Quantity:</label>
                    <input
                      class="form-control"
                      id="recipient-name"
                      type="text"
                      v-model="formData.quantity"
                      :class="
                        v$.formData.quantity.$dirty && v$.formData.quantity.$invalid
                          ? `is-invalid`
                          : v$.formData.quantity.$dirty
                          ? `is-valid`
                          : ``
                      "
                    />
                    <div
                      v-for="error of v$.formData.quantity.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! This field is invalid.
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="col-form-label" for="recipient-name">Size:</label>
                    <select
                      class="form-select"
                      aria-label="Select size"
                      @change="sizeSelected"
                      :class="
                        v$.formData.size_id.$dirty && v$.formData.size_id.$invalid
                          ? `is-invalid`
                          : v$.formData.size_id.$dirty
                          ? `is-valid`
                          : ``
                      "
                    >
                      <option value="" selected>Select...</option>
                      <option v-for="(item, i) in item_sizes" :key="i" :value="item.id">
                        {{ item.title }}
                      </option>
                    </select>
                    <div
                      v-for="error of v$.formData.size_id.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! This field is invalid.
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <label class="col-form-label" for="message-text">Message:</label>
                  <textarea class="form-control" id="message-text"></textarea>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer mx-2">
            <button
              class="btn btn-secondary float-left"
              type="button"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button class="btn btn-success ms-auto" type="button" @click="reStock">
              Restock
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End new usage modal  -->
  </div>
</template>

<script>
import $ from "jquery";
import Choices from "choices.js";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import CountUp from "vue-countup-v3";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import EasyDataTable from "vue3-easy-data-table";
import NavDrawer from "@/components/NavDrawer.vue";
import SimpleTypeahead from "vue3-simple-typeahead";
import FooterComp from "@/components/FooterComp.vue";
import ReportService from "../services/report.service";
import MenuItemsService from "../services/menu_item.service";
import RestaurantService from "../services/restaurant.service";
import { required, email, minLength } from "@vuelidate/validators";

export default {
  name: "InventoryDashboardView",
  mixins: [mixins],
  components: {
    AppBar,
    CountUp,
    NavDrawer,
    FooterComp,
    EasyDataTable,
    SimpleTypeahead,
  },
  data() {
    return {
      file: "",
      searchValue: "",
      modals_key: 1,
      refreshed: 0,
      formData: {
        name: "",
        quantity: "",
        product_id: "",
        size_id: "",
        allow_price_edits: 0,
        separate_inventory_items: 0,
      },
    };
  },
  validations() {
    return {
      formData: {
        size_id: { required },
        product_id: { required },
        quantity: { required },
        user_id: { required },
      },
    };
  },
  created() {
    this.getRestaurant();
  },
  setup() {
    return { v$: useVuelidate() };
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
  },
  methods: {
    async onFileChange(e) {
      console.log(e.target.files[0]);
      this.filename = "Selected File: " + e.target.files[0].name;
      this.file = e.target.files[0];
      await this.updateLogo();
      this.updateResource();
    },
    showCashBtn() {
      this.formData.show_cash_on_pos = !this.formData.show_cash_on_pos;
    },
    showOrderBtn() {
      this.formData.show_order_on_pos = !this.formData.show_order_on_pos;
    },
    showMpesaBtn() {
      this.formData.show_mpesa_on_pos = !this.formData.show_mpesa_on_pos;
    },
    showCardBtn() {
      this.formData.show_card_on_pos = !this.formData.show_card_on_pos;
    },
    updateLogo() {
      this.$store.commit("SET_LOADING", true);
      let formData = new FormData();
      formData.append("id", this.formData.id);
      formData.append("file", this.file);
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };
      RestaurantService.updateLogo(formData, config).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource() {
      this.$store.commit("SET_LOADING", true);
      RestaurantService.update(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getRestaurant();
          } else {
            toast.error(
              response.data.error || response.data.errors.at(0) || response.data.message
            );
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          toast.error(error.response.data.message);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getTimegreetings() {
      const time = new Date().getHours();
      let greetings;
      if (time < 12) greetings = "Good Morning";
      else if (time >= 12 && time <= 17) greetings = "Good Afternoon";
      else greetings = "Good Evening";
      return greetings;
    },
    formatToCurrency(amount) {
      if (amount || amount == 0) {
        const internationalNumberFormat = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        });
        return internationalNumberFormat.format(amount);
      } else {
        return amount;
      }
    },
    getRestaurant() {
      this.$store.commit("SET_LOADING", true);
      return RestaurantService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.formData = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
