<template>
    <div class="sam_loader">
      <div class="wrapper"></div>
      <div class="overlay"></div>
      <div class="spanner show">
        <div class="loader"></div>
      </div>
    </div>
  </template>
  <style scoped>
  .spanner{
    position:fixed;
    background: #2a2a2a55;
    width: 100%;
    display:block;
    text-align:center;
    height: 100%;
    color: #2c7be5;
    transform: translateY(-50%);
    z-index: 2000;
    visibility: hidden;
    top: 50%;
  }
  
  .overlays{
    position: fixed;
      width: 100%;
      height: 100%;
    background: #232f3e;
    visibility: hidden;
  }
  
  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }
  .loader {
    color: #2c7be5;
    font-size: 10px;
    margin: 300px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  
  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
  }
  .loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 3.5em;
  }
  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  
  .show{
    visibility: visible;
  }
  
  .spanner, .overlay{
      opacity: 0;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
  }
  
  .spanner.show, .overlay.sho {
      opacity: 1
  }
  </style>
  <script>
  import { defineComponent } from "vue";
  export default defineComponent({
    name: "LoaderOverlay",
  });
  </script>
  