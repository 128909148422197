import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Main from "./views/Layout/Main";
import JsonExcel from "vue-json-excel3";
import Blank from "./views/Layout/Blank";
import Default from "./views/Layout/Default";
import Select2 from "vue3-select2-component";
import "vue3-autocomplete/dist/vue3-autocomplete.css";

import helpers from './helpers/helpers'

import VCalendar from "v-calendar";
import "v-calendar/style.css";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(VCalendar, {});
// Register components
app.component('Select2', Select2);
app.component('main-layout', Main);
app.component('blank-layout', Blank);
app.component('default-layout', Default);
app.component('downloadExcel', JsonExcel);
// Define global properties
app.config.globalProperties.$helpers = helpers;

app.mount('#app');


// createApp(App)
//   .use(store)
//   .use(router)
//   .use(VCalendar, {})
//   .component("Select2", Select2)
//   .component("main-layout", Main)
//   .component("blank-layout", Blank)
//   .component("default-layout", Default)
//   .component("downloadExcel", JsonExcel)
//     .mount("#app");
