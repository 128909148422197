<template>
  <div>
    <div class="card mb-3">
      <div class="card-body">
        <div class="row justify-content-between align-items-center">
          <div class="col-2">
            <div class="d-inline d-inline">
              <button class="btn btn-falcon-primary btn-sm" type="button" @click="getToday">
                Today
              </button>
            </div>
          </div>
          <div class="col-7">
            <flat-pickr v-model="formData.from" @change="dateChanged" class="form-control d-inline"
              placeholder="Select Start" style="width: 40%" :config="config" />
            To
            <flat-pickr v-model="formData.to" @change="dateChanged" class="form-control d-inline ml-2"
              placeholder="Select Start" style="width: 40%" :config="config" />
          </div>

          <div class="col-3 d-flex">
            <a class="btn btn-falcon-info btn-sm ms-auto me-1 mb-2 mb-sm-0 d-flex" :href="pdfUrl" target="_blank">
              <span class="fas fa-file-pdf m-1"> </span> Pdf
            </a>
            <download-excel :data="resources" name="best-selling-analysis-report-bulla">
              <button class="btn btn-falcon-primary btn-sm me-1 mb-2 mb-sm-0 d-flex" type="button">
                <span class="fas fa-file-excel m-1"> </span>Excel
              </button>
            </download-excel>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-body">
        <div class="row align-items-center text-center">
          <div class="col-sm-6 text-sm-start">
            <img :src="user.company.logo" alt="invoice" style="max-height: 150px" />
          </div>
          <div class="col text-sm-end mt-3 mt-sm-0">
            <h4 class="mb-2">Best Selling Products Report</h4>
            <h5>{{ user.company.name }}</h5>
            <p class="fs--1 mb-0">{{ user.company.address_line1 }}<br>{{ user.company.address_line2 }}</p>
            <p class="fs--1 mb-0">{{ user.company.address_line3 }}</p>
          </div>
        </div>

        <div class="table-responsive scrollbar mt-1 fs--1">
          <table class="table table-striped border-bottom">
            <thead class="light">
              <tr style="background: #eee; border-bottom: 1px solid #ddd; font-weight: bold; color: black;">
                <th class="border-0">NAME</th>
                <th class="border-0">PRICE</th>
                <th class="border-0 text-end">QUANTITY</th>
                <th class="border-0 text-end">TOTAL REVENUE</th>
              </tr>
            </thead>
            <tbody v-if="resources.length > 0">
              <tr v-for="(item, i) in resources" :key="i">
                <td class="mb-0 text-nowrap">
                  {{ item.item_name }}
                </td>
                <td class="mb-0">{{ item.item_price }}</td>
                <td class="mb-0 text-end">{{ item.count }}</td>
                <td class="mb-0 text-end">
                  {{ formatToCurrency(item.item_price * item.count) }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="mb-0 text-nowrap" colspan="5">No records found!</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer bg-light">
        <p class="fs--1 mb-0"><strong>Report by: </strong>Bulla POS!</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import AppBar from "@/components/AppBar.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import ReportService from "../services/report.service";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import OrderTypeService from "../services/order_types.service";

const now = new Date();

export default {
  name: "TicketAnalysisReportView",
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
  },
  created() {
    this.getUser();
    this.getAllData();
    this.getAllOrderTypes();
  },
  computed: {
    pdfUrl() {
      this.formData.company_id = this.user.company_id;
      let token = btoa(JSON.stringify(this.formData));
      return process.env.VUE_APP_BASE_URL + 'best-seller-pdf/' + token;
    }
  },
  data() {
    return {
      summary: [],
      resources: [],
      order_types: [],
      printarea_key: 1,
      preview_modal: false,
      enable_download: false,
      formData: {
        from: moment(new Date()).format("YYYY-MM-DD"),
        to: moment(new Date()).format("YYYY-MM-DD"),
        order_type: "",
      },
      user: {},
      config: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        locale: "en-us",
      },
    };
  },
  methods: {
    orderTypeSelected(e) {
      console.log(e.target.value);
      this.formData.order_type = e.target.value;
      this.getAllData();
    },
    getAllOrderTypes() {
      return OrderTypeService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.order_types = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    formatDate(input) {
      return moment(input).format("DD-MM-YYYY HH:mm:ss");
    },

    getUser() {
      let user = localStorage.getItem("user");
      if (user) {
        this.user = JSON.parse(user);
      } else {
        this.handleLogout();
      }
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    dateChanged() {
      setTimeout(() => {
        this.getAllData();
      }, 500);
    },
    async getToday() {
      this.formData.from = await moment(new Date()).format("YYYY-MM-DD");
      this.formData.to = await moment(new Date()).format("YYYY-MM-DD");
      this.getAllData();
    },
    formatToCurrency(amount) {
      if (amount || amount == 0) {
        const internationalNumberFormat = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        });
        return internationalNumberFormat.format(amount);
      } else {
        return amount;
      }
    },
    getAllData() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.bestSellerReport(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            console.log(response.data.data);
            this.resources = response.data.data;
            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
