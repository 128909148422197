<template>
  <div class="container" data-layout="container">
    <NavDrawer />
    <div class="content">
      <AppBar />
      <slot />
      <FooterComp />
    </div>
  </div>
</template>

<script>
import AppBar from "@/components/AppBar.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import AuthService from "../../services/auth.service";

export default {
  name: "MainLayout",
  components: {
    AppBar,
    NavDrawer,
    FooterComp,
  },
  data: () => ({
    drawer: true,
    mini: true,
    page: "Dashboard",
  }),
  async created() {
    let status = await AuthService.checkAuth();
    if (status == 401) {
      localStorage.clear();
      alert("Session expired");
      this.$router.push("/login");
    }
  },
  mounted() {
    try {
      var isFluid = JSON.parse(localStorage.getItem("isFluid"));
      if (isFluid) {
        var container = document.querySelector("[data-layout]");
        container.classList.remove("container");
        container.classList.add("container-fluid");
      }
      var navbarStyle = localStorage.getItem("navbarStyle");
      if (navbarStyle && navbarStyle !== "transparent") {
        document
          .querySelector(".navbar-vertical")
          .classList.add(`navbar-${navbarStyle}`);
      }
      var navbarPosition = localStorage.getItem("navbarPosition");
      var navbarVertical = document.querySelector(".navbar-vertical");
      var navbarTopVertical = document.querySelector(".content .navbar-top");
      var navbarTop = document.querySelector(
        "[data-layout] .navbar-top:not([data-double-top-nav"
      );
      var navbarDoubleTop = document.querySelector("[data-double-top-nav]");
      var navbarTopCombo = document.querySelector(
        '.content [data-navbar-top="combo"]'
      );

      if (localStorage.getItem("navbarPosition") === "double-top") {
        document.documentElement.classList.toggle("double-top-nav-layout");
      }

      if (navbarPosition === "top") {
        navbarTop.removeAttribute("style");
        navbarTopVertical.remove(navbarTopVertical);
        navbarVertical.remove(navbarVertical);
        navbarTopCombo.remove(navbarTopCombo);
        navbarDoubleTop.remove(navbarDoubleTop);
      } else if (navbarPosition === "combo") {
        navbarVertical.removeAttribute("style");
        navbarTopCombo.removeAttribute("style");
        navbarTop.remove(navbarTop);
        navbarTopVertical.remove(navbarTopVertical);
        navbarDoubleTop.remove(navbarDoubleTop);
      } else if (navbarPosition === "double-top") {
        navbarDoubleTop.removeAttribute("style");
        navbarTopVertical.remove(navbarTopVertical);
        navbarVertical.remove(navbarVertical);
        navbarTop.remove(navbarTop);
        navbarTopCombo.remove(navbarTopCombo);
      } else {
        navbarVertical.removeAttribute("style");
        navbarTopVertical.removeAttribute("style");
        navbarTop.remove(navbarTop);
        navbarDoubleTop.remove(navbarDoubleTop);
        navbarTopCombo.remove(navbarTopCombo);
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
