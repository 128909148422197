// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class OrderTypeService {
    posOrderType() {
        return axios.get(VUE_APP_BASE_URL + 'order-type-pos', { headers: authHeader() })
    }
    index() {
        return axios.get(VUE_APP_BASE_URL + 'order-type', { headers: authHeader() })
    }
    show(id) {
        return axios.get(VUE_APP_BASE_URL + 'order-type/'+id, { headers: authHeader() })
    }
    store(resource) {
        return axios.post(VUE_APP_BASE_URL + 'order-type', resource, { headers: authHeader() })
    }
    update(resource) {
        return axios.put(VUE_APP_BASE_URL + 'order-type/' + resource.id, resource, { headers: authHeader() })
    }
    delete(resource) {
        return axios.delete(VUE_APP_BASE_URL + 'order-type/' + resource.id, { headers: authHeader() })
    }
    storeSingle(resource) {
        return axios.post(VUE_APP_BASE_URL + 'order-type-store', resource, { headers: authHeader() })
    }
}

export default new OrderTypeService()